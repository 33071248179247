import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AWS from 'aws-sdk';


export const saveFileToS3 = createAsyncThunk(
    's3Save/saveFileToS3',
    async (payload, { rejectWithValue }) => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: payload?.settings?.IdentityPoolId,
            IdentityId: payload?.settings?.IdentityId,
            Logins: {
                'cognito-identity.amazonaws.com': payload?.settings?.Token
            }
        });

        AWS.config.update({ region: payload?.settings?.region, credentials: AWS.config.credentials });
        const s3 = new AWS.S3({apiVersion: '2006-03-01'});

        const params = {
            Bucket: payload?.settings?.bucket + "/private/" + payload?.settings?.IdentityId,
            Key: payload?.fileKey,
            Body: payload?.fileContent,
            ContentType: 'text/plain'
        };

        try {
            return await s3.putObject(params).promise();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const appSlice = createSlice({
    name: 'app',
    initialState: {
        appDeleted: {},
        showDeleteConfirmation: false,
        showWaitListForm: true,
        stream: {},
        status: '',
        output: '',
        files: {},
        complete: false,
        currentFile: null,
        appFiles: [],
        fileLoadError: null,
        fileContent: null,
        downloadComplete: false,
        downloadError: null,
    },
    reducers: {
        toggleAppDeleted(state, action) {
            const { appDeleted } = action.payload;
            state.appDeleted = appDeleted;
        },
        toggleDeleteConfirmation(state, action) {
            const { showDeleteConfirmation } = action.payload;
            state.showDeleteConfirmation = showDeleteConfirmation;
        },
        toggleWaitListForm(state, action) {
            const { showWaitListForm } = action.payload;
            state.showWaitListForm = showWaitListForm;
        },
        setCurrentFile(state, action) {
            state.currentFile = action.payload;
        },
        setFileContent(state, action) {
            state.fileContent = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('app/updateAppStream', (state, action) => {
                const { status, output } = action.payload;
                if (!state.stream[status]) {
                    state.stream[status] = { status, output: '' };
                }
                state.stream[status].output += output ? '\n' + output : '';
                state.status = status;
                state.output = output || '';
                if (status === 'File Generation') {
                    const { file_path, chunk } = JSON.parse(output);
                    if (!state.files[file_path]) {
                        state.files[file_path] = '';
                    }
                    state.files[file_path] += chunk;
                }
                if (status === 'Complete') {
                    state.complete = true;
                }
            })
            .addCase('app/updateAppFiles', (state, action) => {
                const { files, error=null } = action.payload;
                state.appFiles = files;
                state.fileLoadError = error;
            })
            .addCase('app/updateFileContent', (state, action) => {
                const { fileContent } = action.payload;
                state.fileContent = fileContent;
            })
            .addCase('app/updateAppDownload', (state, action) => {
                const { downloadComplete, downloadError=null } = action.payload;
                state.downloadComplete = downloadComplete;
                state.downloadError = downloadError;
            })
            .addCase(saveFileToS3.fulfilled, (state, action) => {
                // You might want to update some state here to indicate successful save
            });
    },
});

export const {
    toggleAppDeleted,
    toggleDeleteConfirmation,
    toggleWaitListForm,
    updateAppStream,
    updateAppFiles,
    setCurrentFile,
    updateFileContent,
} = appSlice.actions;
export default appSlice.reducer;
