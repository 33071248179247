import {createSlice} from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    errors: [],
    open: false,
  },
  reducers: {
    setError(state, action) {
      const {errors} = action.payload;
      state.errors = errors;
    },
    toggleModal(state, action) {
      const {open} = action.payload;
      state.open = open;
    },
  },
});

export const {setError, toggleModal} = errorSlice.actions;
export default errorSlice.reducer;
