import * as React from 'react';
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

const Subscription = () => {
    const {isAuthenticated} = useSelector(state => state.login);

    React.useEffect(() => {
        if (!isAuthenticated) {
            window.location.href = '/auth?cta=authenticate';
        }
    }, [isAuthenticated]);

    return(
        <>
            <Sidebar />
            <Header />
            <div className="content">
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Subscription;
