import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../reducers/loginSlice";

const Header = () => {
    const {user} = useSelector(state => state.login);
    const remaining = user?.usage_limit - user?.usage;
    const dispatch = useDispatch();
    return(
        <nav className="navbar navbar-top fixed-top navbar-expand" id="navbarDefault">
            <div className="collapse navbar-collapse justify-content-between">
                <div className="navbar-logo">

                    <button className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                    <a className="navbar-brand me-1 me-sm-3" href="/">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center"><img src="/assets/img/icons/logo.png" alt="phoenix" width="27" />
                                <p className="logo-text ms-2 d-none d-sm-block">Graxle</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="navbar-nav navbar-nav-icons flex-row">
                    <div className="nav-item me-3">
                        <a href="/credits" className="btn btn-warning">
                            Buy Credits
                        </a>
                    </div>
                    <div className="nav-item">
                        Usage Limit: {user?.usage_limit} API requests ({remaining} remaining)
                    </div>
                    <div className="nav-item dropdown">
                        <button className="nav-link" style={{minWidth: '2.25rem'}} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell" style={{height:'20px', width:'20px'}}><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg></button>

                        <div className="dropdown-menu dropdown-menu-end notification-dropdown-menu py-0 shadow border navbar-dropdown-caret" id="navbarDropdownNotfication" aria-labelledby="navbarDropdownNotfication">
                            <div className="card position-relative border-0">
                                <div className="card-header p-2">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="text-body-emphasis mb-0">Notificatons</h5>

                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="scrollbar-overlay" style={{height: '27rem'}} data-simplebar="init"><div className="simplebar-wrapper" style={{margin: '0px'}}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"></div><div className="simplebar-placeholder" style={{
                                        width: '0px', height: '0px'
                                    }}></div></div><div className="simplebar-track simplebar-horizontal" style={{visibility: 'hidden'}}><div className="simplebar-scrollbar" style={{width: '0px', display: 'none'}}></div></div><div className="simplebar-track simplebar-vertical" style={{visibility: 'hidden'}}><div className="simplebar-scrollbar" style={{height: '0px', display: 'none', transform: 'translate3d(0px, 0px, 0px)'}}></div></div></div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="nav-item dropdown">
                        <a className="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar avatar-l ">
                                {user?.photo ? (
                                    <img className="rounded-circle " src={user?.photo} alt="" />
                                ): (
                                    <button type="button" className="btn btn-secondary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"></path>
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border" aria-labelledby="navbarDropdownUser">
                            <div className="card position-relative border-0">
                                <div className="card-body p-0">
                                    <div className="text-center pt-4 pb-3">
                                        <h6 className="mt-1 text-body-emphasis">{user?.first_name} {user?.last_name}</h6>
                                    </div>

                                </div>

                                <div className="card-footer border-translucent">
                                    <div className="px-3"> <button className="btn btn-phoenix-secondary d-flex flex-center w-100" onClick={() => {dispatch(logoutUser())}}> <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out me-2"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>Sign out</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;
