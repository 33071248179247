import {configureStore} from '@reduxjs/toolkit';
import collectionReducer from './reducers/collectionSlice';
import appReducer from './reducers/appSlice';
import loginReducer from './reducers/loginSlice';
import datasetReducer from './reducers/datasets';
import errorReducer from './reducers/errorSlice';
import {apiSlice} from './api/apiSlice';
import prdReducer from './reducers/prdSlice';
import subscriptionReducer from './reducers/subscriptionSlice';
export const store = configureStore({
  reducer: {
      login: loginReducer,
      app: appReducer,
      error: errorReducer,
      collections: collectionReducer,
      datasets: datasetReducer,
      prd: prdReducer,
      subscription: subscriptionReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false,
      }).concat(apiSlice.middleware),
});
