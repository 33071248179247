import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {toggleDeleteCollectionField} from "../../../../reducers/collectionSlice";
import {useDeleteCollectionFieldMutation} from "../../../../api/apiSlice";
import Button from "react-bootstrap/Button";

const ConfirmDeleteCollectionField = ({app, collection, refetch}) => {
    const {showDeleteCollectionField, activeField} = useSelector(state => state.collections);
    const {token} = useSelector(state => state.login);
    const [deleteCollectionField, deleteCollectionFieldResult] = useDeleteCollectionFieldMutation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (deleteCollectionFieldResult?.status === "fulfilled") {
            refetch();
            dispatch(toggleDeleteCollectionField({showDeleteCollectionField: false, activeField: undefined}));
        }
    }, [dispatch, deleteCollectionFieldResult, refetch]);

    return(
        <>
            <Modal
                show={showDeleteCollectionField}
                onHide={() => {
                    dispatch(toggleDeleteCollectionField({showDeleteCollectionField: false, activeField: undefined}));
                }}
            >
                <Modal.Body>
                    Are you sure you want to delete this property? This action will delete all the data under this column. Deletion cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <div className="float-end">
                        <Button
                            variant="danger"
                            onClick={() => {
                                deleteCollectionField({
                                    "token": token,
                                    "body": {app_id: app?.uuid},
                                    "values": {uuid: activeField?.uuid, collection: collection}
                                });
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ConfirmDeleteCollectionField;
