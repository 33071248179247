import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Speedometer, CheckAll } from "react-bootstrap-icons";
import UserSubscriptionDetails from "../Subscription/UserSubscriptionDetails";
import MyCoolCodeBlock from "../Developers/Collections/APIDocs/Code";
import { Tabs, Tab } from 'react-bootstrap';
const Landing = () => {
    const { isAuthenticated } = useSelector(state => state.login);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (isAuthenticated) navigate("/!");
    })
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/">Graxle</Link>
                    <div className="navbar-nav ml-auto">
                        <Link className="nav-item nav-link" to="/auth?cta=register">Sign up</Link>
                        <Link className="nav-item nav-link" to="/auth?cta=authenticate">Login</Link>
                        <Link className="nav-item nav-link" to="/documentation">Documentation</Link>
                        <a className="nav-item nav-link" href="#pricing">Pricing</a>
                    </div>
                </div>
            </nav>

            <div className="container h-100">
                <section className="pb-8" id="home">
                    <div className="container-small hero-header-container px-lg-7 px-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-12 text-center pb-6 position-relative">
                                <div className="text-white">
                                    <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">Turn ideas into apps! Instantly.</h1>
                                    <p className="mb-5">Graxle speeds up the time from idea to production by autogenerating and managing your API endpoints and databases and providing a starter frontend React app that lets you go to market in days, not weeks.</p>
                                    <Link to="/auth?cta=register" className="btn btn-lg btn-outline-light rounded-pill me-3">Try it for free, get warp speed to market!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-soft pt-5 pt-5" id="features">
                    <div className="container-small px-lg-7 px-xxl-3">
                        <div className="position-relative z-index-2">
                            <div className="mb-12 row">
                                <div className="text-center text-lg-start pe-xxl-3 col-lg-6">
                                    <h4 className="text-primary fw-bolder mb-4">Features</h4>
                                    <h2 className="mt-8 mb-3 text-white lh-base">AI is changing the way we build apps. Graxle is here to help you build, iterate, and ship your ideas faster.</h2>
                                </div>
                                <div className="mt-7 text-center text-lg-start col-lg-3 col-sm-6">
                                    <div className="h-100 d-flex flex-column justify-content-between">
                                        <div className="border-start-lg border-dashed ps-4">
                                            <Speedometer size={32} title="Fast" color="white" />
                                            <div className="mt-4">
                                                <h5 className="fw-bolder mb-2">Fast Development</h5>
                                                <p className="fw-semi-bold lh-sm">Go from idea to functional app in minutes, with a React frontend starter and flexible API endpoints.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-7 text-center text-lg-start col-lg-3 col-sm-6">
                                    <div className="h-100 d-flex flex-column">
                                        <div className="border-start-lg border-dashed ps-4">
                                            <CheckAll size={32} title="Complete" color="white" />
                                            <div className="mt-4">
                                                <h5 className="fw-bolder mb-2">Full-Stack Solution</h5>
                                                <p className="fw-semi-bold lh-sm">Generate a product requirements document (PRD), auto-generate API endpoints, and get started with React frontend code in minutes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-soft pt-5 pt-5" id="how-it-works">
                    <div className="container-small px-lg-7 px-xxl-3">
                        <h2 className="text-center text-white mb-5">How Graxle Works</h2>
                        <p className="text-center text-white mb-5">
                            Graxle Copilot gets you started with an autogenerated Product Requirements Document (PRD), API endpoints, and a personalized React starter template.
                        </p>
                        <h3 className="text-white mb-3 text-center">Accessing Your API</h3>
                        <p className="text-white mb-3 text-center">Once your API is generated, you can access it using any programming language.</p>
                        <Tabs defaultActiveKey="javascript" id="code-examples" className="mb-3">
                            <Tab eventKey="javascript" title="React">
                                <MyCoolCodeBlock 
                                    language="javascript" 
                                    code={`import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  baseQuery: fetchBaseQuery({ 
    baseUrl: 'https://api.graxle.com/',
    prepareHeaders: (headers, { getState }) => {
      headers.set('X-API-KEY', 'your-api-key')
      headers.set('Authorization', 'Token your-jwt-token')
      return headers
    },
  }),
  endpoints: (builder) => ({
    getData: builder.query({
      query: () => 'your_endpoint',
    }),
  }),
})

export const { useGetDataQuery } = api`}
                                />
                            </Tab>
                            <Tab eventKey="python" title="Python">
                                <MyCoolCodeBlock 
                                    language="python" 
                                    code={`import requests

url = 'https://api.graxle.com/your_endpoint'
headers = {
    'X-API-KEY': 'your-api-key',
    'Authorization': 'Token your-jwt-token'
}

response = requests.get(url, headers=headers)
print(response.json())`}
                                />
                            </Tab>
                            <Tab eventKey="ruby" title="Ruby">
                                <MyCoolCodeBlock 
                                    language="ruby" 
                                    code={`require 'net/http'
require 'json'

uri = URI('https://api.graxle.com/your_endpoint')
http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = true

request = Net::HTTP::Get.new(uri)
request['X-API-KEY'] = 'your-api-key'
request['Authorization'] = 'Token your-jwt-token'

response = http.request(request)
puts response.body`}
                                />
                            </Tab>
                            <Tab eventKey="php" title="PHP">
                                <MyCoolCodeBlock 
                                    language="php" 
                                    code={`<?php
$url = 'https://api.graxle.com/your_endpoint';
$headers = array(
    'X-API-KEY: your-api-key',
    'Authorization: Token your-jwt-token'
);

$curl = curl_init($url);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_HTTPHEADER, $headers);

$response = curl_exec($curl);
curl_close($curl);

echo $response;
?>`}
                                />
                            </Tab>
                            <Tab eventKey="java" title="Java">
                                <MyCoolCodeBlock 
                                    language="java" 
                                    code={`import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

HttpClient client = HttpClient.newHttpClient();
HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("https://api.graxle.com/your_endpoint"))
    .header("X-API-KEY", "your-api-key")
    .header("Authorization", "Token your-jwt-token")
    .build();

HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`}
                                />
                            </Tab>
                            <Tab eventKey="nodejs" title="Node.js">
                                <MyCoolCodeBlock 
                                    language="javascript" 
                                    code={`const https = require('https');

const options = {
  hostname: 'api.graxle.com',
  port: 443,
  path: '/your_endpoint',
  method: 'GET',
  headers: {
    'X-API-KEY': 'your-api-key',
    'Authorization': 'Token your-jwt-token'
  }
};

const req = https.request(options, (res) => {
  let data = '';

  res.on('data', (chunk) => {
    data += chunk;
  });

  res.on('end', () => {
    console.log(JSON.parse(data));
  });
});

req.end();`}
                                />
                            </Tab>
                            <Tab eventKey="go" title="Go">
                                <MyCoolCodeBlock 
                                    language="go" 
                                    code={`package main

import (
    "fmt"
    "io/ioutil"
    "net/http"
)

func main() {
    url := "https://api.graxle.com/your_endpoint"
    req, _ := http.NewRequest("GET", url, nil)

    req.Header.Add("X-API-KEY", "your-api-key")
    req.Header.Add("Authorization", "Token your-jwt-token")

    res, _ := http.DefaultClient.Do(req)

    defer res.Body.Close()
    body, _ := ioutil.ReadAll(res.Body)

    fmt.Println(string(body))
}`}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </section>

                <section className="bg-soft pt-5 pt-5" id="pricing">
                    <div className="container-small px-lg-7 px-xxl-3">
                        <h2 className="text-center text-white mb-5">Pricing</h2>
                        <UserSubscriptionDetails />
                    </div>
                </section>
            </div>
        </>
    )
}

export default Landing;
