import * as React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const NoApp = () => {
    return(
        <div className="flex-center content-min-h vstack">
            <div className="text-center py-9">
                <Icon.MenuApp size={48} className="img-fluid mb-7 d-light-none" />
                <p className="text-800 fw-normal mb-5">No apps have been created. The fastest way to get started is with Copilot.</p>
                <Link to="/!/copilot" className="btn btn-sm btn-light btn me-2"><Icon.Robot /> Try Copilot</Link>
                <Link to="/!/apps/new" className="btn btn-sm btn-light btn"><Icon.Plus />Create an app manually</Link>
                <p className="mt-4">Checkout the getting started <a href="/!/learn">tutorial.</a></p>
            </div>
        </div>
    );
}

export default NoApp;
