import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useDeleteCollectionMutation} from "../../../api/apiSlice";
import {toggleDeleteCollection} from "../../../reducers/collectionSlice";

const ConfirmDeleteCollection = ({app, refetch}) => {
    const {showDeleteCollection, activeCollection} = useSelector(state => state.collections);
    const {token} = useSelector(state => state.login);
    const [deleteCollection, deleteCollectionResult] = useDeleteCollectionMutation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (deleteCollectionResult?.status === "fulfilled") {
            refetch();
            dispatch(toggleDeleteCollection({showDeleteCollection: false, activeCollection: undefined}));
        }
    }, [dispatch, deleteCollectionResult, refetch]);

    return(
        <>
            <Modal
                show={showDeleteCollection}
                onHide={() => {
                    dispatch(toggleDeleteCollection({showDeleteCollection: false, activeCollection: undefined}));
                }}
            >
                <Modal.Body>
                    Are you sure you want to delete this collection? This action will delete all the data in the collection. Deletion cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <div className="float-end">
                        <Button
                            variant="danger"
                            onClick={() => {
                                deleteCollection({
                                    "token": token,
                                    "body": {app_id: app?.uuid},
                                    "values": {uuid: activeCollection?.uuid}
                                });
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ConfirmDeleteCollection;
