import * as React from 'react';
import * as Icon from "react-bootstrap-icons";

const Commits = () => {
    // add dataset form
    //  load datasets
    return(
        <>
            <div className="row gx-6 gy-3 mb-4 align-items-center">
                <div className="col-auto">
                    <h2 className="mb-0">Datasets</h2>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5">
                        <Icon.Plus /> New dataset
                    </button>
                </div>
            </div>
        </>
    )
}

export default Commits;
