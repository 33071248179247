import * as React from "react";
import {Link, useOutletContext} from "react-router-dom";
import {useDispatch} from "react-redux";
import DeleteApp from "./DeleteApp";
import {toggleDeleteConfirmation} from "../../../reducers/appSlice";
import Collections from "../Collections";

const SingleAppLanding = () => {
    const dispatch = useDispatch();
    const {app, isLoading} = useOutletContext();
    const edit_link = "/!/apps/" + app?.uuid + "/edit";

    return(
        <>

            {isLoading ? (
                <p className="p-4">Working...</p>
            ): (
                <>
                    <div className="row gx-6 gy-3 mb-4 align-items-center">
                        <div className="col-auto">
                            <h2 className="mb-0">{app?.name}</h2>
                        </div>
                        <div className="col-auto">
                            {
                                app?.platform_manager === false
                                &&
                                <>
                                    <Link to={`/!/ide/${app?.uuid}`} className="btn btn-sm btn-outline-secondary me-2">View Code</Link>
                                    <Link to={edit_link} className="btn btn-sm btn-outline-secondary me-2">Edit</Link>
                                    <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => {
                                        dispatch(toggleDeleteConfirmation({
                                            showDeleteConfirmation: true
                                        }))
                                    }}>
                                        Delete
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    <div className="border-bottom">
                        <p className="mt-3 mb-3">{app?.description}</p>
                    </div>
                    <div className="mt-3 mb-3">
                        <Collections app={app} />
                    </div>
                    <DeleteApp app={app} />
                </>
            ) }
        </>
    );
};

export default SingleAppLanding;
