import * as React from 'react';
import * as Icon from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {addSchemaField} from "../../../reducers/datasets";
import DisplaySchemaFields from "./DisplaySchemaFields";
import {Form} from "react-final-form";

const SchemaForm = () => {
    const {schemaFields} = useSelector(state => state.datasets);
    const dispatch = useDispatch();

    const submit = (values) => {
        console.log(values);
    }

    const validate = (values) => {
        let errors = {};
        console.log(values);
        return errors;
    }
    return(
        <>
            <div className="col-auto">
                <div className="dropdown d-inline-block">
                    <button className="btn btn-phoenix-secondary dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Icon.Plus /> Add Field</button><span className="caret"> </span>
                    <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton">
                        <button
                            className="dropdown-item"
                            onClick={() => {
                                dispatch(addSchemaField({
                                    "field": {
                                        "type": "text",
                                        "name": "Text",
                                        "required": false,
                                        "default": "",
                                        "allow_duplicates": false,
                                    }
                                }));
                            }}
                        >Text</button>
                        <button
                            className="dropdown-item"
                            onClick={() => {
                                dispatch(addSchemaField({
                                    "field": {
                                        "type": "number",
                                        "name": "Number",
                                        "required": false,
                                        "default": "",
                                        "allow_duplicates": false,
                                    }
                                }));
                            }}
                        >Number</button>
                        <button
                            className="dropdown-item"
                            onClick={() => {
                                dispatch(addSchemaField({
                                    "field": {
                                        "type": "timestamp",
                                        "name": "Timestamp",
                                        "required": false,
                                        "default": "",
                                        "allow_duplicates": false,
                                    }
                                }));
                            }}
                        >Date</button>
                        <button
                            className="dropdown-item"
                            onClick={() => {
                                dispatch(addSchemaField({
                                    "field": {
                                        "type": "geolocation",
                                        "name": "Geolocation",
                                        "required": false,
                                        "default": "",
                                        "allow_duplicates": false,
                                        "format": "raw_coordinates"
                                    }
                                }));
                            }}
                        >Geolocation</button>
                        <button
                            className="dropdown-item"
                            onClick={() => {
                               dispatch(addSchemaField({
                                    "field": {
                                        "type": "file",
                                        "name": "File",
                                        "required": false,
                                        "default": "",
                                        "allow_duplicates": false,
                                    }
                                }));
                            }}
                        >File</button>
                    </div>
                </div>
            </div>
            <Form
                onSubmit={submit}
                validate={validate}
                render={( {handleSubmit, form} ) => {
                    return(
                        <form onSubmit={handleSubmit}>
                            <DisplaySchemaFields handleSubmit={handleSubmit} fields={schemaFields} form={form} />
                        </form>
                    )
                }}
            />
        </>
    )
}

export default SchemaForm;
