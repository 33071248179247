import * as React from "react";
import TextField from "./TextField";
import NumberField from "./NumberField";
import TimestampField from "./TimestampField";
import GeolocationField from "./GeolocationField";
import FileField from "./FileField";

const DisplaySchemaFields = ({fields, form}) => {
    let isLoading = false;
    return(
        <>
            {
                fields.map((field, index) => {
                    switch(field.type) {
                        case "text":
                            return(
                                <TextField isLoading={isLoading} key={index} position={index} />
                            )
                        case "number":
                            return(
                                <NumberField isLoading={isLoading} key={index} position={index} />
                            )
                        case "timestamp":
                            return(
                                <TimestampField isLoading={isLoading} key={index} position={index} />
                            )
                        case "geolocation":
                            return(
                                <GeolocationField isLoading={isLoading} key={index} position={index} />
                            )
                        case "file":
                            return(
                                <FileField isLoading={isLoading} key={index} position={index} form={form} />
                            )
                        default:
                            return(
                                <TextField isLoading={isLoading} key={index} position={index} />
                            )
                    }
                })
            }
        </>
    )
}

export default DisplaySchemaFields;
