import * as React from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {toggleAPIDocs} from "../../../../reducers/collectionSlice";
import {useGetCollectionByIDQuery} from "../../../../api/apiSlice";

const APIDocumentation = ({app}) => {
    const {token} = useSelector(state => state.login);
    const {showAPIDocs, activeCollectionID} = useSelector(state => state.collections);
    const dispatch = useDispatch();

    const {
        data: collection,
        isLoading,
        refetch
    } = useGetCollectionByIDQuery({
        "token": token,
        "app": app,
        "activeCollectionID": activeCollectionID
    });

    const allowedMethods = typeof collection !== "undefined" ? collection?.authentication_required: "";

    React.useEffect(() => {
        refetch();
    }, [refetch]);

    return(
        <>
            {
                showAPIDocs ? (
                    <Modal
                        show={showAPIDocs}
                        fullscreen={true}
                        onHide={() => {
                            dispatch(toggleAPIDocs({
                                "showAPIDocs": false
                            }))
                        }}>
                        <Modal.Header closeButton>
                            <Modal.Title>{collection?.name} API Reference</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {allowedMethods === "" ? "The " + collection?.name + " endpoint doesn't accept requests.": "The " + collection?.name + " endpoint accepts the following requests: " + allowedMethods}
                            </p>
                            <p>
                                The {collection?.name} endpoint uuid is <code>{collection?.uuid}</code>
                            </p>
                            <p>
                                Include or exclude Authorization header to your requests based on the authentication requirements of the collection. If the {collection?.name} requires authentication to access either POST, PUT, GET, or DELETE methods, you must include the Authorization header with a valid token. Otherwise, you can exclude the Authorization header.
                            </p>
                            <p>
                                To link resources, add a "linked_resources": ["linked_resource_id_1", "linked_resource_id_2"] property to the request body. The value of the linked_resources field must be an array of resource IDs.
                            </p>
                            {isLoading ? <p className="text-muted">Working...</p>: null}
                            {
                                !isLoading
                                &&
                                <>
                                    { allowedMethods !== "" ? (
                                        allowedMethods.split(",").map((method, index) => {
                                            const MethodDocs = React.lazy(() => import('./' + method.toUpperCase() + 'Docs'));
                                            return(
                                                <React.Suspense key={index} fallback={<div>Loading...</div>}>
                                                    <MethodDocs app={app} collection={collection} />
                                                </React.Suspense>
                                            )
                                        })
                                    ) : null }
                                </>
                            }
                        </Modal.Body>
                    </Modal>
                ): null
            }
        </>
    )
};

export default APIDocumentation;
