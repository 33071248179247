import * as React from "react";
import MyCoolCodeBlock from "../Developers/Collections/APIDocs/Code";
import {Robot} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";

const Learn = () => {
    const navigate = useNavigate();
    return(
        <div>
            <div className="mb-4">
                <h2 className="mb-2 lh-sm">Getting Started</h2>
                <p>Welcome to Graxle! The fastest way to get started is to use Graxle Copilot:</p>

                <button onClick={() => navigate("/!/copilot")} className="btn btn-light mb-4"><Robot /> Try Copilot</button>

                <p>This guide will help you to understand how to use the UI to create endpoints and consume them in your code.</p>
            </div>

            <div className="gx-3 gy-4 mb-7 row">
                <div className="order-1 order-xl-0 col-xl-10 col-12">
                    <div className="mb-4 shadow-none border border-300 overflow-visible card">
                        <div id="running_in_local_environment" className="p-4 border-bottom border-300 bg-soft hover-actions-trigger card-header">
                            <div className="g-3 justify-content-between align-items-center row">
                                <div className="col-md col-12">
                                    <h4 className="text-900 text-nowrap mb-0">Create an App</h4>
                                </div>
                            </div>
                        </div>
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <p>From the Sidebar click Apps to reveal the New App link and create an app called Hireme.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-1 order-xl-0 col-xl-10 col-12">
                    <div className="mb-4 shadow-none border border-300 overflow-visible card">
                        <div id="running_in_local_environment" className="p-4 border-bottom border-300 bg-soft hover-actions-trigger card-header">
                            <div className="g-3 justify-content-between align-items-center row">
                                <div className="col-md col-12">
                                    <h4 className="text-900 text-nowrap mb-0">Create Collections</h4>
                                </div>
                            </div>
                        </div>
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <p>Under Apps click on the app you just created. On the top right corner of the individual app page, click the New Collection button to create a collection. Allow POST, PUT, GET, and DELETE access for your collection.</p>
                                <p>When you create an app, Graxle generates an API key for your app. It also automatically adds a "users" collection to the app. You can modify the collection at anytime.
                                    The users collection allows you to create, edit, and delete user accounts programmatically.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-1 order-xl-0 col-xl-10 col-12">
                    <div className="mb-4 shadow-none border border-300 overflow-visible card">
                        <div id="running_in_local_environment" className="p-4 border-bottom border-300 bg-soft hover-actions-trigger card-header">
                            <div className="g-3 justify-content-between align-items-center row">
                                <div className="col-md col-12">
                                    <h4 className="text-900 text-nowrap mb-0">Add a user to the users collection</h4>
                                </div>
                            </div>
                        </div>
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <p>
                                    To sign up a new user, you can send a POST request to the users endpoint provided by Graxle.
                                </p>

                                <p>Include the following headers:</p>

                                <ul>
                                    <li>Content-Type: application/json</li>
                                    <li>X-API-KEY: your_app_api_key</li>
                                </ul>

                                <p className="mt-4">
                                    The request body should include the following json payload:
                                </p>

                                <MyCoolCodeBlock language="javascript" code={'{\n' +
                                    '  "first_name":"new_user_first_name",\n' +
                                    '  "last_name":"new_user_last_name",\n' +
                                    '  "email":"new_user_email",\n' +
                                    '  "password":"new_user_password"\n' +
                                    '}\n'}
                                />

                                <p className="mt-4">
                                    Here's an example signup request:
                                </p>

                                <MyCoolCodeBlock language="javascript" code={'curl -X POST your_app_users_collection_endpoint -H "X-API-KEY: your-api-key" \n' +
                                    '-H "Content-Type: application/json" -d \'{"first_name":"new_user_first_name","last_name":"new_user_last_name","username":"new_user_email","password":"new_user_password"}\'\n'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-1 order-xl-0 col-xl-10 col-12">
                    <div className="mb-4 shadow-none border border-300 overflow-visible card">
                        <div id="running_in_local_environment" className="p-4 border-bottom border-300 bg-soft hover-actions-trigger card-header">
                            <div className="g-3 justify-content-between align-items-center row">
                                <div className="col-md col-12">
                                    <h4 className="text-900 text-nowrap mb-0">Next we'll log the user in</h4>
                                </div>
                            </div>
                        </div>
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <p>
                                    To login a user, send a POST request to the users collection endpoint.
                                </p>

                                <p>Include the following headers:</p>

                                <ul>
                                    <li>Content-Type: application/json</li>
                                    <li>X-API-KEY: your_app_api_key</li>
                                </ul>

                                <p>
                                    The request body should include the following payload:
                                </p>

                                <MyCoolCodeBlock language="javascript" code={'{\n' +
                                    '  "email":"new_user_email",\n' +
                                    '  "password":"new_user_password"\n' +
                                    '}\n'} />

                                <p className="mt-3">
                                    Here's an example login request:
                                </p>

                                <MyCoolCodeBlock language="javascript" code={'curl -X POST your_app_users_collection_endpoint -H "X-API-KEY: your-api-key" \n ' +
                                    '-H "Content-Type: application/json" \n' +
                                    '-d \'{"email":"new_user_email","password":"new_user_password"}\'\n'} />

                                <p className="mt-3">
                                    The response of this request will contain a token which you should store and use for the Authorization header in any request that requires authentication.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-4 shadow-none border border-300 overflow-visible card">
                    <div id="running_in_local_environment" className="p-4 border-bottom border-300 bg-soft hover-actions-trigger card-header">
                        <div className="g-3 justify-content-between align-items-center row">
                            <div className="col-md col-12">
                                <h4 className="text-900 text-nowrap mb-0">
                                    Validating User Existence
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="p-0 card-body">
                        <div className="p-4">
                            <p>You can validate whether a user exists by sending a request to your_endpoint/resource_exists_by_value</p>
                            Example Request:
                            <MyCoolCodeBlock language={"javascript"} code={'curl -X POST your_endpoint/resource_exists_by_value -H "X-API-KEY: your-api-key"\n' +
                                '                                -H "Content-Type: application/json"\n' +
                                '                                -d \'{"value": "user_email", "label": "email", "tokenize": true}\''} />
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button onClick={() => navigate("/!")} className="btn btn-light mb-4">Get started building your app</button>
                </div>
            </div>
        </div>
    )
}

export default Learn;
