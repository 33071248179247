import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { BASE_URL, APP_API_KEY } from '../constants';
import AWS from 'aws-sdk';
export const updatePRD = createAction('prd/updatePRD');
export const updateAppStream = createAction('app/updateAppStream');
export const updateAppFiles = createAction('app/updateAppFiles');
export const updateFileContent = createAction('app/updateFileContent');
export const updateAppDownload = createAction('app/updateAppDownload');

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
});


export const createPRDThunk = createAsyncThunk(
  'prd/create',
  async (data, { dispatch }) => {
    const response = await fetch(BASE_URL + 'prd_manager', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-Api-Key": APP_API_KEY,
        Authorization: 'Token ' + data?.token,
      },
      body: JSON.stringify({ description: data?.description }),
    });

    if (!response.ok) {
      throw new Error('Failed to create PRD');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let finalPRD = null;

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.trim() !== '') {
          try {
            const parsedData = JSON.parse(line);
            if (parsedData.status === 'generating') {
              dispatch(updatePRD(parsedData));
            } else if (parsedData.status === 'complete') {
              finalPRD = parsedData.prd;
            }
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      }
    }

    if (!finalPRD) {
      throw new Error('Failed to generate PRD');
    }

    return finalPRD;
  }
);

export const createAppFromPRDThunk = createAsyncThunk(
  'app/createFromPRD',
  async (data, { dispatch }) => {
    const response = await fetch(BASE_URL + 'generate_app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-Api-Key": APP_API_KEY,
        Authorization: 'Token ' + data?.token,
      },
      body: JSON.stringify({ prd_uuid: data?.prd_uuid }),
    });

    if (!response.ok) {
      throw new Error('Failed to create app from PRD');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');


      for (const line of lines) {
        console.log(line);
        if (line.trim() !== '') {
          try {
            const parsedData = JSON.parse(line);
            dispatch(updateAppStream(parsedData));
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      }
    }
  }
);

export const fetchAppFromS3 = createAsyncThunk(
  's3Fetch/fetchAppFromS3',
  async (payload, { dispatch }) => {
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: payload?.settings?.IdentityPoolId,
          IdentityId: payload?.settings?.IdentityId,
          Logins: {
              'cognito-identity.amazonaws.com': payload?.settings?.Token
          }
      });

      AWS.config.update({ region: payload?.settings?.region, credentials: AWS.config.credentials });
      const s3 = new AWS.S3({apiVersion: '2006-03-01'});

      const params = {
          Bucket: payload?.settings?.bucket,
          Prefix: `private/${payload?.app_uuid}/`
      };

      try {
          const listAllKeys = async (params) => {
              const data = await s3.listObjectsV2(params).promise();
              let contents = data.Contents;
              let allKeys = contents.map(item => item.Key);

              if (data.IsTruncated) {
                  params.ContinuationToken = data.NextContinuationToken;
                  allKeys = allKeys.concat(await listAllKeys(params));
              }

              const prefix = `private/${payload?.app_uuid}/`;
              return allKeys.map(key => key.replace(prefix, ''));
          };

          const allKeys = await listAllKeys(params);
          return dispatch(updateAppFiles({
              files: allKeys
          }));
      } catch (error) {
          return dispatch(updateAppFiles({
              error: error
          }));
      }
  }
);

export const fetchFileContentFromS3 = createAsyncThunk(
  's3Fetch/fetchFileContentFromS3',
  async (payload, { dispatch }) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: payload?.settings?.IdentityPoolId,
      IdentityId: payload?.settings?.IdentityId,
      Logins: {
        'cognito-identity.amazonaws.com': payload?.settings?.Token
      }
    });

    AWS.config.update({ region: payload?.settings?.region, credentials: AWS.config.credentials });
    const s3 = new AWS.S3({apiVersion: '2006-03-01'});

    const params = {
      Bucket: payload?.settings?.bucket,
      Key: `private/${payload?.app_uuid}/${payload?.path}`
    };

    try {
      const data = await s3.getObject(params).promise();
      const fileContent = data.Body.toString('utf-8');
      return dispatch(updateFileContent({
        fileContent: fileContent
      }));
    } catch (error) {
      return dispatch(updateFileContent({
        fileContentError: error
      }));
    }
  }
);

export const downloadAppFilesFromS3 = createAsyncThunk(
  's3Download/downloadAppFilesFromS3',
  async (payload, { dispatch }) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: payload?.settings?.IdentityPoolId,
      IdentityId: payload?.settings?.IdentityId,
      Logins: {
        'cognito-identity.amazonaws.com': payload?.settings?.Token
      }
    });

    AWS.config.update({ region: payload?.settings?.region, credentials: AWS.config.credentials });
    const s3 = new AWS.S3({apiVersion: '2006-03-01'});

    try {
      // List all objects in the app's directory
      const listParams = {
        Bucket: payload?.settings?.bucket,
        Prefix: `private/${payload?.app_uuid}/`
      };

      const listedObjects = await s3.listObjectsV2(listParams).promise();

      // Download all files
      const downloadPromises = listedObjects.Contents.map(async (object) => {
        const getParams = {
          Bucket: payload?.settings?.bucket,
          Key: object.Key
        };
        const data = await s3.getObject(getParams).promise();
        return {
          path: object.Key.replace(`private/${payload?.app_uuid}/`, ''),
          content: data.Body
        };
      });

      const files = await Promise.all(downloadPromises);

      // Create a zip file
      const JSZip = require('jszip');
      const zip = new JSZip();
      console.log('Files:', files);
      if (files && Array.isArray(files)) {
        files.forEach((file) => {
          if (file && file.path && file.content) {
            zip.file(file.path, file.content);
          }
        });
      } else {
        console.error('Files is not an array or is undefined');
      }

      // Generate the zip file
      const zipContent = await zip.generateAsync({type: 'blob'});

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(zipContent);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${payload?.app_uuid}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return dispatch(updateAppDownload({
        downloadComplete: true,
        downloadError: null
      }));
    } catch (error) {
      return dispatch(updateAppDownload({
        downloadComplete: false,
        downloadError: error
      }));
    }
  }
);





export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  endpoints: builder => ({
    joinWaitList: builder.mutation({
      query: data => ({
        url: 'users/wait_list',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          email: data.email ? data.email : ''
        },
      }),
    }),
    signUp: builder.mutation({
      query: data => ({
        url: 'users',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          email: data.email ? data.email : '',
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          password: data.password ? data.password : '',
        },
      }),
    }),
    createApp: builder.mutation({
      query: data => ({
        url: typeof data?.values?.uuid === "undefined" ? 'apps': 'apps/' + data?.values?.uuid,
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          uuid: data?.values?.uuid ? data?.values?.uuid : '',
          name: data?.values?.name ? data?.values?.name : '',
          description: data?.values?.description ? data?.values?.description : '',
          regenerate_api_key: data?.values?.regenerate_api_key ? data?.values?.regenerate_api_key : false
        },
      }),
    }),
    deleteApp: builder.mutation({
      query: data => ({
        url: 'apps/' + data?.app?.uuid,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    testPrompt: builder.mutation({
      query: data => ({
        url: "prompts/test_gpt3_prompt",
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          model: data?.values?.model ? data?.values?.model : "",
          temperature: data?.values?.temperature ? data?.values?.temperature : "",
          max_tokens: data?.values?.max_tokens ? data?.values?.max_tokens : "",
          top_p: data?.values?.top_p ? data?.values?.top_p : "",
          frequency_penalty: data?.values?.frequency_penalty ? data?.values?.frequency_penalty : "",
          presence_penalty: data?.values?.presence_penalty ? data?.values?.presence_penalty : "",
          prompt: data?.values?.prompt ? data?.values?.prompt : "",
        },
      }),
    }),
    createAPPJsonWithCopilot: builder.mutation({
      query: data => ({
        url: 'copilot',
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          description: data?.description
        },
      }),
    }),
    createCollection: builder.mutation({
      query: data => ({
        url: typeof data?.values?.uuid === "undefined" ? 'data_types': 'data_types/' + data?.values?.uuid,
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          app_id: data?.app?.uuid,
          uuid: data?.values?.uuid,
          name: data?.values?.name,
          description: data?.values?.description,
          created_by_self: data?.values?.created_by_self,
          authentication_required: data?.values?.authentication_required,
          endpoint: data?.values?.endpoint
        },
      }),
    }),
    deleteCollection: builder.mutation({
      query: data => ({
        url: 'data_types/' + data?.values?.uuid,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.body,
      }),
    }),
    createCollectionField: builder.mutation({
      query: data => ({
        url: typeof data?.values?.uuid === "undefined" ? 'data_types/' + data?.values?.collection?.uuid + '/fields': 'data_types/' + data?.values?.collection?.uuid + '/fields/' + data?.values?.uuid,
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.body,
      }),
    }),
    deleteCollectionField: builder.mutation({
      query: data => ({
        url: 'data_types/' + data?.values?.collection?.uuid + '/fields/' + data?.values?.uuid,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.body,
      }),
    }),
    startFileImport: builder.mutation({
      query: data => ({
        url: 'files/import_data',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          "data_type_id": data?.activeCollectionID,
          "file_id": data?.file_id,
          "app_id": data?.app_id,
        },
      }),
    }),
    saveFileData: builder.mutation({
      query: data => ({
        url: 'files',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: data?.file,
      }),
    }),
    isValidUser: builder.mutation({
      query: data => ({
        url: 'users/is_valid_user',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          "X-Api-Key": APP_API_KEY,
        },
        body: {
          email: data.email ? data.email : '',
          tokenize: data.tokenize ? data.tokenize : false,
        },
      }),
    }),
    getDataTypeData: builder.query({
      query: data => ({
        url: 'data_types/data/' + data?.activeCollectionID +'?app_id=' + data?.app_id + '&start=' + data?.start + '&limit=' + data?.limit,
        method: 'GET',
        headers: {
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getActivation: builder.query({
      query: data => ({
        url: 'users/activate/' + data?.link,
        method: 'GET',
      }),
    }),
    getApps: builder.query({
      query: data => ({
        url: 'apps?start=1&limit=100',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getCollections: builder.query({
      query: data => ({
        url: 'data_types?start=1&limit=100&app_id=' + data?.app?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getCollectionByID: builder.query({
      query: data => ({
        url: 'data_types/' + data?.activeCollectionID + '?app_id=' + data?.app?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getCollectionData: builder.query({
      query: data => ({
        url: 'data_types/data/' + data?.activeCollectionID +'?app_id=' + data?.app_id + '&start=' + data?.start + '&limit=' + data?.limit,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getTestPromptByID: builder.query({
      query: data => ({
        url: 'prompts/test_gpt3_prompt/' + data?.uuid,
        method: 'GET',
        headers: {
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getFileUploadAccess: builder.query({
      query: data => ({
        url: 'auth/request_upload_access',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    getAppByID: builder.query({
      query: data => ({
        url: 'apps/' + data?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    createPRD: builder.mutation({
      queryFn: async (data, api, extraOptions, baseQuery) => {
        try {
          const result = await api.dispatch(createPRDThunk(data)).unwrap();
          return { data: result };
        } catch (error) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      },
    }),
    createAppFromPRD: builder.mutation({
      queryFn: async (data, api, extraOptions, baseQuery) => {
        try {
          const result = await api.dispatch(createAppFromPRDThunk(data)).unwrap();
          return { data: result };
        } catch (error) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      },
    }),
    updatePRD: builder.mutation({
      query: data => ({
        url: `prd_manager/${data?.prd_id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
        body: { 
          prd: data?.prd
         },
      }),
    }),
    getPRD: builder.query({
      query: data => ({
        url: `prd_manager/${data?.prd_id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    deletePRD: builder.mutation({
      query: data => ({
        url: `prd_manager/${data?.prd_id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),
    fetchPaymentIntentClientSecret: builder.mutation({
      query: data => ({
        url: 'payment_intents',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
        body: {
          amount: data.amount ? data.amount : '',
          metadata: data.metadata ? data.metadata : {},
        },
      }),
    }),
    getPaymentIntent: builder.query({
      query: data => ({
        url: 'payment_intents?payment_intent_id=' + data?.paymentIntentId,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
      }),
    }),
    createSubscription: builder.mutation({
      query: data => ({
        url: 'subscriptions',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
        body: {
          plan: data?.plan,
          tool: data?.tool,
          start_date: data?.start_date,
          paid_amount: data?.paid_amount,
        }
      }),
    }),
    cancelSubscription: builder.mutation({
      query: data => ({
        url: 'subscriptions/' + data?.uuid,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
        body: {
          plan: data?.plan,
          tool: data?.tool,
          start_date: data?.start_date,
          paid_amount: data?.paid_amount,
        }
      }),
    }),
    getSubscription: builder.query({
      query: data => ({
        url: 'subscriptions',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          'X-Api-Key': APP_API_KEY,
        },
      }),
    }),
    getPlans: builder.query({
      query: data => ({
        url: 'subscription_plans',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-Api-Key': APP_API_KEY,
        },
      }),
    }),
    getUserStatsByID: builder.query({
      query: data => ({
        url: 'prompt_qe2k9l6i/stats_by_user?start_timestamp=0&end_timestamp=' + Date.now(),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": APP_API_KEY,
        },
      }),
    }),

    sendNotification: builder.mutation({
      query: data => ({
          url: 'notifications',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'X-API-KEY': APP_API_KEY,
          },
          body: {
            messages: data?.messages,
          },
      }),
  }),
  }),
});

export const {
  useGetAppsQuery,
  useGetAppByIDQuery,
  useGetCollectionByIDQuery,
  useGetActivationQuery,
  useGetCollectionsQuery,
  useGetFileUploadAccessQuery,
  useGetDataTypeDataQuery,
  useGetCollectionDataQuery,
  useIsValidUserMutation,
  useDeleteAppMutation,
  useDeleteCollectionMutation,
  useDeleteCollectionFieldMutation,
  useJoinWaitListMutation,
  useCreateAppMutation,
  useCreateAPPJsonWithCopilotMutation,
  useCreateCollectionMutation,
  useCreateCollectionFieldMutation,
  useSignUpMutation,
  useSaveFileDataMutation,
  useStartFileImportMutation,
  useTestPromptMutation,
  useGetTestPromptByIDQuery,
  useCreatePRDMutation,
  useCreateAppFromPRDMutation,
  useUpdatePRDMutation,
  useDeletePRDMutation,
  useGetPRDQuery,
  useSendNotificationMutation,
  useFetchPaymentIntentClientSecretMutation,
  useGetPaymentIntentQuery,
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
  useGetPlansQuery,
} = apiSlice;

// Action creator for updating PRD progress
export const updatePRDProgress = (chunk) => ({
  type: 'prd/updateProgress',
  payload: chunk,
});

// Action creator for updating App progress
export const updateAppProgress = (chunk) => ({
  type: 'app/updateProgress',
  payload: chunk,
});
