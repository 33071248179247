import * as React from "react";
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {toggleDataTypeOverlay} from "../../../reducers/collectionSlice";
import {capitalize} from "../../../constants";


const DataTypeOverlay = ({app, collection, refetch}) => {
    const {showDataTypeOverlay, activeDataType, activeField} = useSelector(state => state.collections);
    const dispatch = useDispatch();
    const allowedDataTypes = ["text", "number", "boolean", "date", "timestamp", "location", "url", "email", "json", "password", "select", "gpt"];
    const DataTypeForm = React.lazy(() => import('./Properties/' + capitalize(activeDataType) + 'Form'));

    return(
        <>
            {
                showDataTypeOverlay ? (
                    <Modal
                        show={showDataTypeOverlay}
                        fullscreen={true}
                        onHide={() => {
                            dispatch(toggleDataTypeOverlay({
                                "showDataTypeOverlay": false,
                                "activeDataType": activeDataType,
                                "activeField": undefined
                            }))
                        }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Property Type: {capitalize(activeDataType)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            { allowedDataTypes.includes(activeDataType) ? (
                                <React.Suspense fallback={<div>Loading...</div>}>
                                    <DataTypeForm
                                        app={app}
                                        collection={collection}
                                        refetch={refetch}
                                        activeField={activeField}
                                    />
                                </React.Suspense>
                            ): null }
                        </Modal.Body>
                    </Modal>
                ): null
            }
        </>
    )
};

export default DataTypeOverlay;
