import * as React from "react";
import {useSelector} from "react-redux";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useGetAppByIDQuery} from "../../api/apiSlice";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

const Home = () => {
    const {isAuthenticated} = useSelector(state => state.login);
    const navigate = useNavigate();
    const { uuid } = useParams();
    const {token} = useSelector( state => state.login );
    const {
        data: app,
        isLoading
    } = useGetAppByIDQuery({
        "token": token,
        "uuid": uuid
    }, {skip: typeof uuid === "undefined"});

    React.useEffect(() => {
        if ( !isAuthenticated ) navigate("/auth?cta=authenticate");
    })

    return (
        <>
            <Sidebar />
            <Header />
            <div className="content">
                <Outlet context={{app, isLoading}} />
            </div>
            <Footer />
        </>
    );
}

export default Home;
