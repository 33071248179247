import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {toggleExitSchemaFormConfirmation, toggleSchemaForm} from "../../../reducers/datasets";

const ConfirmExitSchemaForm = () => {
    const {showExitSchemaFormConfirmation} = useSelector(state => state.datasets);
    const dispatch = useDispatch();

    return(
        <>
            <Modal
                show={showExitSchemaFormConfirmation}
                onHide={() => {
                    dispatch(toggleExitSchemaFormConfirmation({
                        "showExitSchemaFormConfirmation": false,
                    }));
                }}
            >
                <Modal.Body>
                    Exit the schema builder form? You will lose all schema data you have entered.
                </Modal.Body>
                <Modal.Footer>
                    <div className="float-end">
                        <Button
                            variant="primary"
                            onClick={() => {
                                dispatch(toggleExitSchemaFormConfirmation({
                                    "showExitSchemaFormConfirmation": false,
                                }));
                                dispatch(toggleSchemaForm({
                                    "showSchemaForm": false,
                                    "schemaFields": [],
                                }));
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ConfirmExitSchemaForm;
