import * as React from "react";
import {Field, Form} from "react-final-form";
import {useDeleteAppMutation} from "../../../api/apiSlice";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toggleAppDeleted, toggleDeleteConfirmation} from "../../../reducers/appSlice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteApp = ({app}) => {
    const {showDeleteConfirmation} = useSelector( state => state.app);
    const {token} = useSelector( state => state.login);
    const [validated, setValidated] = useState(false);
    const [deleteApp, deleteAppResult] = useDeleteAppMutation();
    const className = validated ? "danger": "secondary";
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkAppName = (input, app) => {
        if ( input?.value?.trim() === app?.name) {
            setValidated(true);
        } else if ( validated === true ) {
            setValidated(false);
        }
    }

    const submit = values => {
        if (values?.name?.trim() === app?.name) {
            deleteApp({
                "token": token,
                "app": app
            })
        }
    };

    React.useEffect(() => {
        if (deleteAppResult?.status === "fulfilled") {
            dispatch(toggleAppDeleted({"appDeleted" : app}));
            dispatch(toggleDeleteConfirmation({showDeleteConfirmation: false}))
            navigate("/!");
        }
    }, [app, deleteAppResult, dispatch, navigate])

    return(
        <>
            <Modal
                show={showDeleteConfirmation}
                onHide={() => {
                    dispatch(toggleDeleteConfirmation({
                        showDeleteConfirmation: false
                    }))
                }}
            >
                <Form
                    onSubmit={submit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete App</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Deleting an app cannot be undone. To delete the app, enter the app's name
                                    ({app?.name}) below to confirm that you want to permanently delete it:</p>
                                <Field
                                    name="name"
                                    render={({ input, meta }) => (
                                        <div>
                                            <label className="form-label">Name</label>
                                            <input
                                                {...input}
                                                className="form-control"
                                                placeholder="App Name"
                                                autoComplete="off"
                                                disabled={deleteAppResult?.isLoading}
                                                onKeyDown={() => {
                                                    checkAppName(input, app)
                                                }}
                                                onKeyUp={() => {
                                                    checkAppName(input, app)
                                                }}

                                            />
                                            {meta.touched && meta.error && <span>{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-primary" onClick={() => {
                                    form.reset();
                                    setValidated(false);
                                    dispatch(toggleDeleteConfirmation({
                                        showDeleteConfirmation: false
                                    }))
                                }}>
                                   Cancel
                                </Button>
                                <Button
                                    variant={className}
                                    disabled={validated === false}
                                    onClick={handleSubmit}
                                >
                                    Delete App
                                </Button>
                            </Modal.Footer>
                        </form>
                    )}
                />
            </Modal>
        </>
    )
};

export default DeleteApp;
