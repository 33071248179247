import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form} from "react-final-form";
import {useCreateCollectionMutation} from "../../../api/apiSlice";
import {stripHtml} from "string-strip-html";
import {toggleNewCollection, toggleCollectionForm} from "../../../reducers/collectionSlice";
import {Typeahead} from "react-bootstrap-typeahead";

const CollectionForm = ({app}) => {
    const {token} = useSelector(state => state.login);
    const {showCollectionForm, activeCollection} = useSelector(state => state.collections);
    const dispatch = useDispatch();
    const [createCollection, createCollectionResult] = useCreateCollectionMutation();

    const submit = values => {
        if ( values?.name !== '' && values?.description !== '') {
            values.name = stripHtml(values?.name)?.result;
            values.description = stripHtml(values?.description)?.result;
            if (typeof activeCollection?.uuid !== "undefined") values.endpoint = activeCollection?.endpoint;
            createCollection({
                "token": token,
                "values": values,
                "app": app
            });
        }
    };

    const validate = values => {
        let errors = {};
        if (!values?.name || values?.name?.trim() === "") {
            errors.name = "You forgot to name your collection."
        }
        if (!values?.description || values?.description?.trim() === "") {
            errors.description = "You forgot to provide a description of your collection."
        }

        if (typeof values?.authentication_required === "undefined") {
            errors.authentication_required = "You forgot to select whether authentication is required."
        }

        if (typeof values?.created_by_self === "undefined") {
            errors.created_by_self = "You forgot to select whether the collection is a list of users."
        }
        return errors;
    };

    React.useEffect(() => {
        if (createCollectionResult?.status === "fulfilled" && createCollectionResult?.data?.hasOwnProperty("uuid")) {
            dispatch(toggleCollectionForm({showCollectionForm: false, activeCollection: undefined}));
            dispatch(toggleNewCollection({newCollection: createCollectionResult?.data}));
        }
    }, [createCollectionResult, dispatch])

    return(
        showCollectionForm ? (
                <Modal
                    show={showCollectionForm}
                    fullscreen={true}
                    onHide={() => {
                        dispatch(toggleCollectionForm({
                            showCollectionForm: false,
                            activeCollection: undefined
                        }));
                    }}
                >
                    <Modal.Body>
                        <Form
                            onSubmit={submit}
                            validate={validate}
                            initialValues={{
                                "uuid": typeof activeCollection?.uuid !== "undefined" ? activeCollection?.uuid : undefined,
                                "name": typeof activeCollection?.name !== "undefined" ? activeCollection?.name : "",
                                "description": typeof activeCollection?.description !== "undefined" ? activeCollection?.description : "",
                                "created_by_self": typeof activeCollection?.created_by_self !== "undefined" ? activeCollection?.created_by_self : false,
                                "authentication_required": typeof activeCollection?.authentication_required !== "undefined" ? activeCollection?.authentication_required : [],
                            }}
                            render={( {handleSubmit, form} ) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
                                        <h1 className="h6">New Collection</h1>
                                        <div>
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() => {
                                                    dispatch(toggleCollectionForm({
                                                        showCollectionForm: false,
                                                        activeCollection: undefined
                                                    }))
                                                }}
                                            >
                                                Cancel
                                            </Button>{' '}
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                disabled={createCollectionResult?.isLoading}
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                    <Field
                                        name="name"
                                        render={({ input, meta }) => (
                                            <div>
                                                <label className="form-label fw-bold">Collection Name</label>
                                                <input
                                                    {...input}
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder="My Collection"
                                                    disabled={createCollectionResult?.isLoading}
                                                />
                                                {!createCollectionResult?.isLoading ? (
                                                    meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                    <Field
                                        name="description"
                                        render={({ input, meta }) => (
                                            <div className="mt-4">
                                                <label className="form-label fw-bold">Description</label>
                                                <textarea
                                                    {...input}
                                                    className="form-control"
                                                    placeholder="Describe the data that will be stored in this collection"
                                                    disabled={createCollectionResult?.isLoading}
                                                />
                                                {!createCollectionResult?.isLoading ? (
                                                    meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                ) : null}
                                            </div>
                                        )}
                                    />

                                    <Field
                                        name="created_by_self"
                                        type="checkbox"
                                        render={({ input, meta }) => (
                                            <div className="form-check mt-4">
                                                <input
                                                    {...input}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="created_by_self"
                                                    disabled={createCollectionResult?.isLoading}
                                                    defaultValue={typeof activeCollection?.created_by_self !== "undefined" ? activeCollection?.created_by_self : false}
                                                />
                                                <label className="form-check-label fw-bold" htmlFor="created_by_self">User Collection</label>
                                                <p className="text-muted">Check this box to indicate that this collection will hold a list of users</p>
                                                {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                                            </div>
                                        )}
                                    />

                                    <Field
                                        name="authentication_required"
                                        render={({ input, meta }) => (
                                            <div className="mt-4">
                                                <label className="form-label fw-bold" htmlFor="authentication_required">Require API Request Authentication</label>
                                                <Typeahead
                                                    id="authentication_required"
                                                    labelKey="name"
                                                    multiple={true}
                                                    allowNew={false}
                                                    onChange={(selected) => {
                                                        form.change("authentication_required", selected);
                                                    }}
                                                    options={['POST', 'GET', 'PUT', 'DELETE']}
                                                    placeholder="Require authentication for POST, GET, PUT, or DELETE requests"
                                                    disabled={createCollectionResult?.isLoading}
                                                    defaultSelected={typeof activeCollection?.authentication_required !== "undefined" && activeCollection?.authentication_required?.trim() !== "" ? activeCollection?.authentication_required?.trim()?.split(",") : []}
                                                />
                                                {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                                            </div>
                                        )}
                                    />

                                    {
                                        typeof createCollectionResult.error !== 'undefined' &&
                                        createCollectionResult?.error?.data?.errors.map((error, index) => {
                                            return (
                                                <div className="bg-warning p-4 mt-4" key={index}>
                                                    {error}
                                                </div>
                                            );
                                        })
                                    }
                                </form>
                            )
                            }
                        />
                    </Modal.Body>
                </Modal>
            ) : null
    )
};

export default CollectionForm;
