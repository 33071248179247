import * as React from "react";
import * as Icon from "react-bootstrap-icons";
import {useDispatch} from "react-redux";
import {toggleSchemaForm} from "../../../reducers/datasets";
import SchemaFormOverlay from "./SchemaOverlay";
import ConfirmExitSchemaForm from "./ConfirmExitSchemaForm";

const Schemas = ({app}) => {
    const dispatch = useDispatch();
    return(
        <>
            <div className="row gx-6 gy-3 mb-4 align-items-center">
                <div className="col-auto">
                    <h2 className="mb-0">Schemas</h2>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary px-5"
                        onClick={() => {
                            dispatch(toggleSchemaForm({
                                "showSchemaForm": true,
                                "schemaFields": [],
                            }))
                        }}
                    >
                        <Icon.Plus /> New Schema
                    </button>
                </div>
            </div>
            <SchemaFormOverlay app={app} />
            <ConfirmExitSchemaForm />
        </>
    )
}

export default Schemas;
