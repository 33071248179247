import {createSlice} from '@reduxjs/toolkit';
import jwtDecode from "jwt-decode";

const userSlice = createSlice({
  name: 'login',
  initialState: {
    token: localStorage.getItem('token'),
    user: localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')): {},
    isAuthenticated: localStorage.getItem('token') !== null,
    reset_password_email: '',
    showDeleteConfirmation: false,
  },
  reducers: {
    loginUser(state, action) {
      const {token, user} = action.payload;
      state.token = token;
      state.user = user;
      state.isAuthenticated = Object.keys(user).length !== 0;
      localStorage.setItem('token', token);
    },
    logoutUser(state) {
      state.token = '';
      state.user = {};
      state.isAuthenticated = false;
      // delete storage
      localStorage.removeItem('token');
    },
  },
});

export const {loginUser, logoutUser} =
  userSlice.actions;
export default userSlice.reducer;
