import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown, ChevronRight, Folder, FileEarmark } from 'react-bootstrap-icons';
import { setCurrentFile } from '../../../reducers/appSlice';

const TreeNode = ({ name, path, children, level }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isFolder = !!children;
  const dispatch = useDispatch();
  const {currentFile} = useSelector((state) => state.app);

  const handleClick = () => {
    if (isFolder) {
      setIsOpen(!isOpen);
    } else {
      dispatch(setCurrentFile(path));
    }
  };

  const isCurrentFile = path === currentFile;

  return (
    <>
      <div className={`d-flex align-items-center ${isCurrentFile ? 'bg-light' : ''}`}>
        {isFolder ? (
          <button onClick={handleClick} className="me-1 btn btn-link p-0">
            {isOpen ? <ChevronDown size={8} /> : <ChevronRight size={8} />}
          </button>
        ) : (
          <span className="me-0" style={{ width: '16px', height: '16px' }}></span>
        )}
        {isFolder ? <Folder size={16} className="me-2" /> : <FileEarmark size={16} className="me-2" />}
        <span 
          onClick={handleClick} 
          style={{ 
            cursor: 'pointer',
            fontWeight: isCurrentFile ? 'bold' : 'normal',
          }}
        >
          {name}
        </span>
      </div>
      {isOpen && children && (
        <div className="ms-3">
          {Object.entries(children).map(([childName, childValue]) => (
            <TreeNode key={childName} name={childName} path={`${path}/${childName}`} children={childValue} level={level + 1} />
          ))}
        </div>
      )}
    </>
  );
};

const TreeViewer = ({ files }) => {
  const buildTree = (paths) => {
    const tree = {};
    paths.forEach(path => {
      const parts = path.split('/');
      let currentLevel = tree;
      parts.forEach((part, index) => {
        if (!currentLevel[part]) {
          currentLevel[part] = index === parts.length - 1 ? null : {};
        }
        currentLevel = currentLevel[part];
      });
    });
    return tree;
  };

  const fileTree = buildTree(files);

  return (
    <div className="me-1">
      {Object.entries(fileTree).map(([name, children]) => (
        <TreeNode key={name} name={name} path={name} children={children} level={0} />
      ))}
    </div>
  );
};

const FileTreeView = ({files}) => {
  return (
    <div className="mx-auto">
      <TreeViewer files={files} />
    </div>
  );
}

export default FileTreeView;
