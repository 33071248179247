import React from 'react';
import { useGetSubscriptionQuery, useGetPlansQuery } from "../../api/apiSlice";
import { useSelector, useDispatch } from "react-redux";
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { Check, Star } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { kickstartSubscription } from '../../reducers/subscriptionSlice';

const UserSubscriptionDetails = () => {
    const { token, isAuthenticated, user } = useSelector(state => state.login);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        data: subscriptionData,
        isLoading: isLoadingUserSubscription,
    } = useGetSubscriptionQuery({
        token: token,
    }, {
        skip: !isAuthenticated
    });

    const { data: plansData, isLoading: isLoadingPlans } = useGetPlansQuery();

    const currentSubscription = subscriptionData?.subscription;
    const subscriptionPlans = plansData?.plans || [];
 
    if (isLoadingUserSubscription || isLoadingPlans) {
        return <p className="text-muted">Loading...</p>;
    }

    const referer = !isAuthenticated ? "/credits" : "/";
    const paidPlans = subscriptionPlans.filter(plan => plan.price > 0 && plan.name !== "Enterprise Credit Pack");
    const freePlan = subscriptionPlans.find(plan => plan.price === 0);
    const enterprisePlan = subscriptionPlans.find(plan => plan.name === "Enterprise Credit Pack");

    return (
        <Container className="mt-5">
            <h2 className="text-center mb-4">Simple Pricing Model</h2>
            <p className="text-center mb-5">
                No monthly subscriptions. Simply pay for the credits you need. 
            </p>
            {currentSubscription && (
                <div className="mb-5">
                    <h4 className="text-center">Your Current Package</h4>
                    <p className="text-center">
                        You have {(currentSubscription.request_limit - (user?.usage || 0)).toLocaleString()} credits left.
                        You've used {user?.usage?.toLocaleString() || 0} credits so far.
                    </p>
                </div>
            )}
              {freePlan && !isAuthenticated ? (
                <Row className="mt-5 mb-5">
                    <Col md={12} className="text-center">
                        <h3>{freePlan.name}</h3>
                        <p className="lead">Start for free with {freePlan.request_limit} credits</p>
                        <ul className="list-inline">
                            <li className="list-inline-item me-4">
                                <Check size={18} className="me-2 text-success" />
                                {freePlan.app_limit} app limit
                            </li>
                            <li className="list-inline-item me-4">
                                <Check size={18} className="me-2 text-success" />
                                {freePlan.request_limit} request limit
                            </li>
                            <li className="list-inline-item">
                                <Check size={18} className="me-2 text-success" />
                                {freePlan.completion_limit} completion limit
                            </li>
                        </ul>
                        <Button variant="outline-primary" className="mt-3" onClick={() => {
                            navigate('/auth?cta=register');
                        }}>
                            {currentSubscription?.uuid === freePlan.uuid ? 'Current Plan' : 'Get Started'}
                        </Button>
                    </Col>
                </Row>
            ): null}
            <Row>
                {paidPlans.map((plan) => (
                    <Col key={plan.uuid} md={4} lg={3} className="mb-4">
                        <Card 
                            className={`h-100 ${currentSubscription?.uuid === plan.uuid ? 'border-primary' : ''}`}
                            bg="dark"
                            text="light"
                        >
                            <Card.Header className="text-center">
                                <h3>{plan.name}</h3>
                                <h2>${plan.price}</h2>
                            </Card.Header>
                            <Card.Body>
                                <ul className="list-unstyled">
                                    <li className="mb-2">
                                        <Check size={18} className="me-2 text-primary" />
                                        {plan?.request_limit?.toLocaleString()} credits
                                    </li>
                                    <li className="mb-2">
                                        <Check size={18} className="me-2 text-primary" />
                                        Create {plan?.app_limit?.toLowerCase()} apps
                                    </li>
                                    <li className="mb-2">
                                        <Check size={18} className="me-2 text-primary" />
                                        Submit {plan?.request_limit?.toLocaleString()} API requests
                                    </li>
                                    <li className="mb-2">
                                        <Check size={18} className="me-2 text-primary" />
                                        Use copilot for {plan?.completion_limit?.toLowerCase()} completions
                                    </li>
                                </ul>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                <Button variant="primary" block onClick={() => {
                                    if (!isAuthenticated) {             
                                        localStorage.setItem('referer', referer);
                                        navigate('/auth?cta=register');
                                    } else {
                                        dispatch(kickstartSubscription({
                                            plan: plan,
                                            tool: 'Graxle',
                                        }));
                                        navigate('/credits/buy');
                                    }
                                }}>
                                    {currentSubscription?.uuid === plan.uuid ? 'Current Package' : 'Select Package'}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>
          
            {enterprisePlan && (
                <Row className="mt-5">
                    <Col md={8} lg={6} className="mx-auto">
                        <Card 
                            className="h-100 border-primary shadow-lg"
                            bg="dark"
                            text="light"
                        >
                            <Card.Header className="text-center bg-primary">
                                <Star size={24} className="mb-2" />
                                <h3 className="mb-0">{enterprisePlan.name}</h3>
                            </Card.Header>
                            <Card.Body>
                                <h2 className="text-center mb-4">{enterprisePlan.price}</h2>
                                <p className="text-center lead mb-4">Custom plan tailored to your needs</p>
                                <ul className="list-unstyled">
                                    <li className="mb-3">
                                        <Check size={18} className="me-2 text-primary" />
                                        Unlimited credits
                                    </li>
                                    <li className="mb-3">
                                        <Check size={18} className="me-2 text-primary" />
                                        Priority support
                                    </li>
                                    <li className="mb-3">
                                        <Check size={18} className="me-2 text-primary" />
                                        Custom integrations
                                    </li>
                                    <li className="mb-3">
                                        <Check size={18} className="me-2 text-primary" />
                                        Dedicated account manager
                                    </li>
                                </ul>
                            </Card.Body>
                            <Card.Footer className="text-center">
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    className="px-5"
                                    onClick={() => {
                                        window.location.href = `mailto:chinedu@graxle.com?subject=Enterprise Plan Inquiry&body=I'm interested in learning more about the Enterprise Plan.`;
                                    }}
                                >
                                    Contact Sales
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default UserSubscriptionDetails;
