import * as React from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import Card from "react-bootstrap/Card";
import {useNavigate} from "react-router-dom";
import {FRONTEND_URL} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {setPurchaseError} from "../../reducers/subscriptionSlice";

const CheckoutForm = () => {
    const {purchaseError} = useSelector(state => state.subscription);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: FRONTEND_URL + "/credits/confirmation",
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            dispatch(setPurchaseError({purchaseError: result.error.message}));
        }
    };
    return (

        <Card className="bg-light">
            <Card.Body>
                <form onSubmit={handleSubmit}>
                    <PaymentElement className="bg-light p-3"/>
                    {typeof purchaseError !== "undefined" && <div className="text-danger">{purchaseError}</div>}
                </form>
            </Card.Body>
            <Card.Footer className="pt-0 border-0">
                <div className="float-end">
                <button className="btn btn-primary mt-3" disabled={!stripe} onClick={handleSubmit}>Buy</button>
                    <button className="btn btn-link mt-3" onClick={() => {
                        navigate("/!/copilot");
                    }}>Cancel</button>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default CheckoutForm;
