import * as React from 'react';
import {Field} from "react-final-form";
import * as Icon from "react-bootstrap-icons";
import {useDispatch} from "react-redux";
import {removeSchemaField} from "../../../reducers/datasets";

const TextField = ({isLoading, position}) => {
    const dispatch = useDispatch();
    return(
        <div className="card mt-3">
            <div className="card-header">
                Text Field
            </div>
            <div className="card-body">
                <Field
                    name="name"
                    render={({ input, meta }) => (
                        <div>
                            <label className="form-label fw-bold">Schema Field Name</label>
                            <input
                                {...input}
                                autoComplete="off"
                                className="form-control"
                                placeholder="Enter field name"
                                disabled={isLoading}
                            />
                            {!isLoading ? (
                                meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                            ) : null}
                        </div>
                    )}
                />
                <Field
                    name="required"
                    type="checkbox"
                    render={({ input, meta }) => (
                        <div className="form-check mt-4">
                            <input
                                {...input}
                                type="checkbox"
                                className="form-check-input"
                                id="required"
                                disabled={isLoading}
                            />
                            <label className="form-check-label fw-bold" htmlFor="required">Required</label>
                            <p className="text-muted">Is the field required to have data?</p>
                            {!isLoading ? (
                                meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                            ) : null}

                        </div>
                    )}
                />
            </div>
            <div className="card-footer">
                <button type="button" className="btn btn-phoenix-secondary" onClick={() => {
                    dispatch(removeSchemaField({
                        position: position
                    }));
                }}><Icon.Trash /> Remove</button>
            </div>
        </div>
    );
}

export default TextField;
