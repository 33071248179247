import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MyCoolCodeBlock from '../Collections/APIDocs/Code';
import { API_URL } from '../../../constants';
import { Tabs, Tab } from 'react-bootstrap';

const Documentation = () => {
    const navigate = useNavigate();

    const renderCodeExamples = (endpoint, method, body = null) => {
        const headers = `
'X-API-KEY': 'your-api-key',
'Authorization': 'Token your-jwt-token'`;
        const url = `${API_URL}${endpoint}`;
        
        return (
            <Tabs defaultActiveKey="javascript" id="code-examples" className="mb-3">
                <Tab eventKey="javascript" title="JavaScript">
                    <MyCoolCodeBlock 
                        language="javascript" 
                        code={`fetch('${url}', {
  method: '${method}',
  headers: {
    'Content-Type': 'application/json',
    ${headers}
  }${body ? `,
  body: JSON.stringify(${body})` : ''}
})
.then(response => response.json())
.then(data => console.log(data));`}
                    />
                </Tab>
                <Tab eventKey="python" title="Python">
                    <MyCoolCodeBlock 
                        language="python" 
                        code={`import requests

url = '${url}'
headers = {
    'Content-Type': 'application/json',
    ${headers}
}
${body ? `data = ${body}

response = requests.${method.toLowerCase()}(url, json=data, headers=headers)` : `response = requests.${method.toLowerCase()}(url, headers=headers)`}
print(response.json())`}
                    />
                </Tab>
                <Tab eventKey="ruby" title="Ruby">
                    <MyCoolCodeBlock 
                        language="ruby" 
                        code={`require 'net/http'
require 'json'

uri = URI('${url}')
http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = true

request = Net::HTTP::${method.capitalize}.new(uri)
request['Content-Type'] = 'application/json'
${headers.split('\n').map(header => `request['${header.split(':')[0]}'] = '${header.split(':')[1]?.trim()}'`).join('\n')}
${body ? `request.body = ${body}.to_json` : ''}

response = http.request(request)
puts response.body`}
                    />
                </Tab>
                <Tab eventKey="php" title="PHP">
                    <MyCoolCodeBlock 
                        language="php" 
                        code={`<?php
$url = '${url}';
$headers = array(
    'Content-Type: application/json',
    ${headers.split('\n').map(header => `'${header?.trim()}'`).join(',\n    ')}
);
${body ? `$data = ${body};` : ''}

$curl = curl_init($url);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_CUSTOMREQUEST, '${method}');
curl_setopt($curl, CURLOPT_HTTPHEADER, $headers);
${body ? "curl_setopt($curl, CURLOPT_POSTFIELDS, json_encode($data));" : ''}

$response = curl_exec($curl);
curl_close($curl);

echo $response;
?>`}
                    />
                </Tab>
                <Tab eventKey="java" title="Java">
                    <MyCoolCodeBlock 
                        language="java" 
                        code={`import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

HttpClient client = HttpClient.newHttpClient();
HttpRequest request = HttpRequest.newBuilder()
    .uri(URI.create("${url}"))
    .header("Content-Type", "application/json")
    ${headers.split('\n').map(header => `.header("${header.split(':')[0]}", "${header.split(':')[1]?.trim()}")`).join('\n    ')}
    .${method.toLowerCase()}(${body ? `HttpRequest.BodyPublishers.ofString(${body})` : 'HttpRequest.BodyPublishers.noBody()'})
    .build();

HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`}
                    />
                </Tab>
                <Tab eventKey="nodejs" title="Node.js">
                    <MyCoolCodeBlock 
                        language="javascript" 
                        code={`const https = require('https');

const options = {
  hostname: '${new URL(url).hostname}',
  port: 443,
  path: '${new URL(url).pathname}',
  method: '${method}',
  headers: {
    'Content-Type': 'application/json',
    ${headers}
  }
};

const req = https.request(options, (res) => {
  let data = '';

  res.on('data', (chunk) => {
    data += chunk;
  });

  res.on('end', () => {
    console.log(JSON.parse(data));
  });
});

${body ? `req.write(JSON.stringify(${body}));` : ''}
req.end();`}
                    />
                </Tab>
                <Tab eventKey="go" title="Go">
                    <MyCoolCodeBlock 
                        language="go" 
                        code={`package main

import (
    "fmt"
    "io/ioutil"
    "net/http"
    ${body ? `"strings"` : ''}
)

func main() {
    url := "${url}"
    ${body ? `payload := strings.NewReader(\`${body}\`)` : ''}
    req, _ := http.NewRequest("${method}", url, ${body ? 'payload' : 'nil'})

    req.Header.Add("Content-Type", "application/json")
    ${headers.split('\n').map(header => `req.Header.Add("${header.split(':')[0]}", "${header.split(':')[1]?.trim()}")`).join('\n    ')}

    res, _ := http.DefaultClient.Do(req)

    defer res.Body.Close()
    body, _ := ioutil.ReadAll(res.Body)

    fmt.Println(string(body))
}`}
                    />
                </Tab>
            </Tabs>
        );
    };

    return (
        <div className="documentation-container">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-4">
                <div className="container">
                    <Link className="navbar-brand" to="/">Graxle</Link>
                    <div className="navbar-nav">
                        <Link className="nav-item nav-link" to="/">Home</Link>
                        <Link className="nav-item nav-link" to="/!/copilot">Copilot</Link>
                        <Link className="nav-item nav-link active" to="/documentation">Documentation</Link>
                        <Link className="nav-item nav-link" to="/auth?cta=register">Sign Up</Link>
                        <Link className="nav-item nav-link" to="/auth?cta=authenticate">Login</Link>
                    </div>
                </div>
            </nav>

            <div className="container">
                <h1>Graxle Documentation</h1>

                <section className="mt-2">
                    <h2>Introduction</h2>
                    <p>Welcome to Graxle! This documentation will guide you through using our platform to create and manage your apps, collections, and API interactions. We'll use a Wholesale Clothing Marketplace as an example throughout this guide, but remember that Graxle can be used to build a wide variety of applications.</p>
                </section>

                <section className="mt-2">
                    <h2>Getting Started with Graxle Copilot</h2>
                    <p>The fastest way to get started is to use Graxle Copilot, our AI-powered assistant:</p>
                    <button onClick={() => navigate("/!/copilot")} className="btn btn-primary mb-4">Try Copilot</button>
                    <p>Copilot can help you create and manage your apps and APIs using natural language commands, making the process intuitive and efficient for any type of application you're building.</p>
                </section>

                <section className="mt-2">
                    <h2>Creating an App Manually</h2>
                    <ol>
                        <li>Navigate to the "Apps" section in the sidebar.</li>
                        <li>Click on "New App" to create a new application.</li>
                        <li>Name your app (e.g., "Wholesale Clothing Marketplace" for our example).</li>
                        <li>Once created, Graxle will generate a unique API key for your app.</li>
                        <li>A "users" collection is automatically added to your app, which you can customize later.</li>
                    </ol>
                </section>

                <section className="mt-2">
                    <h2>Understanding Collections</h2>
                    <p>Collections are the building blocks of your Graxle app. They represent different types of data you want to store and manage. For example, in our Wholesale Clothing Marketplace, we might have these collections:</p>
                    <ul>
                        <li><strong>Transactions:</strong> Stores transaction data</li>
                        <li><strong>Inventory:</strong> Manages inventory information</li>
                        <li><strong>Orders:</strong> Tracks order data</li>
                        <li><strong>Products:</strong> Contains product information</li>
                        <li><strong>Users:</strong> Stores user data</li>
                    </ul>
                    <p>Your app can have any number of collections based on your specific needs.</p>
                </section>

                <section className="mt-2">
                    <h2>Creating and Managing Collections</h2>
                    <ol>
                        <li>Go to your app's dashboard.</li>
                        <li>Click the "New Collection" button.</li>
                        <li>Name your collection (e.g., "Transactions" for our example).</li>
                        <li>Set up the collection properties (e.g., for Transactions: payment_records, invoices).</li>
                        <li>Configure access permissions (POST, PUT, GET, DELETE) as needed.</li>
                    </ol>
                </section>

                <section className="mt-2">
                    <h2>Using the API</h2>
                    <h3>Authentication</h3>
                    <p>For authenticated requests, include these headers:</p>
                    <ul>
                        <li>X-API-KEY: your_app_api_key</li>
                        <li>Authorization: Token your-jwt-token (for authenticated endpoints)</li>
                    </ul>

                    <h3>POST Request (Create)</h3>
                    <p>To create a new resource in a collection:</p>
                    {renderCodeExamples('/your_collection_endpoint', 'POST', '{\n    "property1": "value1",\n    "property2": "value2"\n}')}

                    <h3>GET Request (Read)</h3>
                    <p>To retrieve resources from a collection:</p>
                    {renderCodeExamples('/your_collection_endpoint?start=0&limit=10', 'GET')}
                    <p>You can adjust the 'start' and 'limit' parameters to paginate through results.</p>

                    <h3>PUT Request (Update)</h3>
                    <p>To update an existing resource:</p>
                    {renderCodeExamples('/your_collection_endpoint/resource_id', 'PUT', '{\n    "property1": "updated_value1",\n    "property2": "updated_value2"\n}')}

                    <h3>DELETE Request</h3>
                    <p>To delete a resource:</p>
                    {renderCodeExamples('/your_collection_endpoint/resource_id', 'DELETE')}
                </section>

                <section className="mt-2">
                    <h2>Next Steps</h2>
                    <p>Now that you're familiar with the basics of creating and managing apps with Graxle, you're ready to start building your own custom application!</p>
                    <button onClick={() => navigate("/!")} className="btn btn-primary mb-4">Start Building Your App</button>
                </section>
            </div>

            <footer className="bg-dark text-center text-lg-start mt-4">
                <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    © 2023 Graxle, Inc. All rights reserved.
                </div>
            </footer>
        </div>
    );
};

export default Documentation;
