import React from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from 'react-bootstrap';
import { useUpdatePRDMutation } from '../../../api/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import * as Icon from "react-bootstrap-icons";
import { setShowEditModal } from '../../../reducers/prdSlice';
import { useEffect } from 'react';

const PRDForm = ({ content, refetch }) => {
    const {token} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const [updatePRD, updatePRDResult] = useUpdatePRDMutation();

    const validate = values => {
        const errors = {};
        if (!values.prd) {
            errors.prd = 'PRD content is required';
        }
        return errors;
    };

    const submit = values => {
        updatePRD({
            "token": token,
            "prd": values?.prd,
            "prd_id": content?.uuid
        });
    };

    useEffect(() => {
        if (updatePRDResult.isSuccess) {
            dispatch(setShowEditModal(false));
            refetch();
        }
    }, [updatePRDResult.isSuccess, dispatch, refetch]);

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            initialValues={{ 
                prd: content?.prd 
            }}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name="prd"
                        render={({ input, meta }) => (
                            <div className="mb-3">
                                <textarea
                                    {...input}
                                    className="form-control"
                                    rows={10}
                                />
                                {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                            </div>
                        )}
                    />
                    <Button onClick={handleSubmit} disabled={submitting} variant="outline-secondary" className="float-end">
                        <Icon.Save className="me-2" size={16}/>
                        {submitting ? 'Saving...' : 'Save '}
                    </Button>
                </form>
            )}
        />
    );
}

export default PRDForm;