export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const APP_API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const chars = process.env.REACT_APP_CHARS;

export const style = {
    leftPadding: {
        paddingLeft: "5px"
    },
    sideBar: {
        minHeight: "calc(100vh)",
        height: "100%"
    }
};

export const subscription_plans = [
    {"name": "Free Credit Pack", "price": 0.00, "request_limit": 500, "app_limit": 'Unlimited', "completion_limit": 500},
    {"name": "Starter Credit Pack", "price": 50.00, "request_limit": 50000, "app_limit": 'Unlimited', "completion_limit": 'Unlimited'},
    {"name": "Growth Credit Pack", "price": 375.00, "request_limit": 75000, "app_limit": 'Unlimited', "completion_limit": 'Unlimited'},
    {"name": "Scale Credit Pack", "price": 750.00, "request_limit": 150000, "app_limit": 'Unlimited', "completion_limit": 'Unlimited'},
    {"name": "Ultimate Credit Pack", "price": 2250.00, "request_limit": 450000, "app_limit": 'Unlimited', "completion_limit": 'Unlimited'},
    {"name": "Enterprise Credit Pack", "price": "Contact Sales"},
]

export const generatePassword = () => {
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
}

export const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const capitalize = (string)  => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const parseSelectOptions = (options) => {
    const optionsArr = options.split(",");
    let parsedOption = [];
    for(let i = 0; i < optionsArr.length; i++) {
        parsedOption.push(optionsArr[i].trim());
    }
    return parsedOption;
}

export const default_validation = (values) => {
    let errors = {};
    if (typeof values?.required === "undefined" ) {
        errors.required = "You forgot to check if this property is required."
    }

    if (typeof values?.allow_duplicates === "undefined" ) {
        errors.allow_duplicates = "You forgot to check if this property allows duplicates."
    }

    if (typeof values?.editable === "undefined" ) {
        errors.editable = "You forgot to check if this property is editable."
    }
    return errors;
}

export const allowedHtmlTags = [
    'a',
    'b',
    'blockquote',
    'br',
    'caption',
    'cite',
    'col',
    'colgroup',
    'dd',
    'div',
    'dl',
    'dt',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'i',
    'img',
    'li',
    'ol',
    'p',
    'pre',
    'q',
    'small',
    'span',
    'strike',
    'strong',
    'sub',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'u',
    'ul'
];

export const property_details_headers = (fields=[]) => {
    let headers = ["property"];
    for (let i = 0; i < fields?.length; i++) {
        let keys = Object.keys(fields[i]);
        for (let j = 0; j < keys.length; j++) {
            if (!headers.includes(keys[j]) && !["uuid"].includes(keys[j]) && keys[j] !== "name") {
                headers.push(keys[j]);
            }
        }
    }
    return headers;
};

export const validate_field_name = (field, fields=[]) => {
    let error = undefined;
    for (let i = 0; i < fields?.length; i++) {
        if (fields[i].name === field.name && fields[i].uuid !== field.uuid) {
            error = field?.name + " is already in use."
            break;
        }
    }

    if (!field?.name || field?.name?.trim() === "") {
        error = "You forgot to enter a name."
    }
    return {
        name: error
    };
}

export const bodyObject = (fields=[]) => {
    let body = {};
    for (let i = 0; i < fields?.length; i++) {
        body[fields[i]?.name?.toLowerCase()] = "your_" + fields[i]?.name?.toLowerCase();
    }
    return body;
}

export const emails = [
    {
        subject: "Welcome to Graxle Platform! 🚀",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Welcome to Graxle! I'm happy you have decided to give it a try.</p>
          <p>Graxle lets you build API endpoints at the speed of now by blending LLMs with a powerful no-code endpoint generator. My goal is to help you get from idea to production in days. Graxle handles your databases, authentication, API authorization and helps you get started with a starter react frontend. Get started quicly simply by describing your idea to Graxle Copilot. Check it out and let me know what you think.</p>
          <p>If you have any questions, please email me at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a>.</p>
          <p>Happy building!</p>
          <p>Chinedu, Founder & CEO</p>
        </body>
      </html>
    `,
        timestamp: new Date().toISOString()
    },
    {
        subject: "Getting Started with Graxle",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Welcome to Graxle! Let's get you started on your development journey with Graxle Copilot.</p>
          
          <h3>Getting Started with Graxle Copilot</h3>
          <p>Graxle Copilot is your AI-powered assistant that can help you:</p>
          <ul>
            <li>Generate a detailed Product Requirements Document (PRD) from your app idea</li>
            <li>Automatically create API endpoints</li>
            <li>Build a starter React frontend for your app</li>
          </ul>
          <p>To use Copilot, simply login to Graxle and click the Copilot icon in the Sidebar. Describe your app idea and the AI will guide you through the rest!</p>

          <h3>Want to Create Your First App Manually?</h3>
          <ol>
            <li><strong>Create an App:</strong> From the Sidebar, click "Apps" → "New App" and name it "Hireme".</li>
            <li><strong>Create Collections:</strong> In your app page, click "New Collection". Enable POST, PUT, GET, and DELETE access.</li>
            <li><strong>Use Your API Key:</strong> Graxle generates an API key for your app and adds a "users" collection automatically.</li>
          </ol>

          <h3>An Example of Working with Users on the API</h3>
          <p><strong>1. Adding a User:</strong></p>
          <pre>
curl -X POST https://api.graxle.com/apps/v1/<your_users_endpoint_slug> \
-H "X-API-KEY: [YOUR_API_KEY]" \
-H "Content-Type: application/json" \
-d '{
  "first_name":"John",
  "last_name":"Doe",
  "email":"john@example.com",
  "password":"secure_password"
}'
          </pre>

          <p><strong>2. Logging In:</strong></p>
          <pre>
curl -X POST https://api.graxle.com/apps/v1/auth \
-H "X-API-KEY: [YOUR_API_KEY]" \
-H "Content-Type: application/json" \
-d '{
  "email":"john@example.com",
  "password":"secure_password"
}'
          </pre>
          <p>The response will contain a token for authenticated requests.</p>

          <p><strong>3. Validating User Existence:</strong></p>
          <pre>
curl -X POST https://api.graxle.com/apps/v1/<your_users_endpoint_slug>/resource_exists_by_value \
-H "X-API-KEY: [YOUR_API_KEY]" \
-H "Content-Type: application/json" \
-d '{"value": "john@example.com", "label": "email", "tokenize": true}'
          </pre>

          <p>You can get more specific and detailed instructions for each collection of your app by going to Apps > <your_app_name> > Database Collections. Click on the collection documentation.</p>

          <p>Questions or feedback? Reach out to me at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a></p>

          <p>Happy developing!</p>

          <p>Best,<br>Chinedu, Founder & CEO of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 1 * 60 * 60 * 1000).toISOString() // 1 hour later
    },
    {
        subject: "The Inspiration Behind Graxle Platform",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>Curious about the story behind Graxle?</p>
          <p>I created Graxle with a passion for speeding up the development process by merging LLMs with a no-code API endpoint generator and database manager. I've experienced first hand the challenges developers and entrepreneurs face in rapidly bringing their ideas to life. I built Graxle to solve my own problem of spending weeks writing code to get a simple MVP in the hands of users. Typically, to get an app in the hands of users, you write backend, frontend, and database code. Graxle automates this process, allowing me to experiment, learn, and iterate fast.</p>
          <p>With Graxle I aim to make it incredibly easy for you to go from idea to production fast without worrying about databases, authentication, authorization, and eventually data ingestion, model training and serving, and scalability.</p>
          <p>Thank you for being part of this journey. Email me with suggestions at <a href="mailto:chinedu@graxle.com">chinedu@graxle.com</a></p>
          <p>Best,<br>Chinedu, Founder & CEO of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString() // 1 day later
    },
    {
        subject: "How's Your Experience with Graxle?",
        body: `
      <html>
        <body>
          <p>Hi [first_name],</p>
          <p>I hope you're enjoying your experience with Graxle so far!</p>
          <p>I'd love to hear about your journey and how I can help you make the most out of the product. Have you tried generating a PRD or creating API endpoints yet? Here's a link to the <a href="https://docs.graxle.com/learn">documentation</a> to get you started. If you have any questions or need assistance, don't hesitate to reach out.</p>
          <p>Your feedback is crucial to me. Let me know how the product is working for you and what features you'd like to see in the future.</p>
          <p>Looking forward to your response.</p>
          <p>Best,<br>Chinedu, Founder & CEO of Graxle</p>
        </body>
      </html>
    `,
        timestamp: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString() // 3 days later
    }
];