import * as React from "react";
import {Field} from "react-final-form";
import {Typeahead} from "react-bootstrap-typeahead";
import {removeSchemaField} from "../../../reducers/datasets";
import * as Icon from "react-bootstrap-icons";
import {useDispatch} from "react-redux";

const FileField = ({isLoading, position, form}) => {
    const dispatch = useDispatch();
    return(
        <div className="card">
            <div className="card-header">
                <div className="pull-left">
                    File Field
                </div>
            </div>
            <div className="card-body">
                <Field
                    name="name"
                    render={({ input, meta }) => (
                        <div>
                            <label className="form-label fw-bold">Schema Field Name</label>
                            <input
                                {...input}
                                autoComplete="off"
                                className="form-control"
                                placeholder="Enter field name"
                                disabled={isLoading}
                            />
                            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                        </div>
                    )}
                />
                <Field
                    name="required"
                    type="checkbox"
                    render={({ input, meta }) => (
                        <div className="form-check mt-4">
                            <input
                                {...input}
                                type="checkbox"
                                className="form-check-input"
                                id="required"
                                disabled={isLoading}
                            />
                            <label className="form-check-label fw-bold" htmlFor="required">Required</label>
                            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                        </div>
                    )}
                />

                <Field
                    name="options"
                    render={({ input, meta }) => (
                        <div className="mt-4">
                            <label className="form-label fw-bold" htmlFor="authentication_required">Format</label>
                            <Typeahead
                                id="options"
                                labelKey="name"
                                multiple={false}
                                allowNew={false}
                                onChange={(selected) => {
                                    form.change("options", selected);
                                }}
                                options={['png', 'jpeg']}
                                placeholder="Choose allowed file format"
                                disabled={isLoading}
                            />
                            <p className="text-muted mt-2">Choose the type of image files you would like to allow: png or jpeg</p>
                            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                        </div>
                    )}
                />
            </div>
            <div className="card-footer">
                <button type="button" className="btn btn-phoenix-secondary" onClick={() => {
                    dispatch(removeSchemaField({
                        position: position
                    }));
                }}><Icon.Trash /> Remove</button>
            </div>
        </div>
    );
}

export default FileField;
