import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AWS from "aws-sdk";
import {v4 as uuidv4} from 'uuid';
import {APP_API_KEY, BASE_URL} from "../constants";


export const fetchCollectionData = createAsyncThunk('data/fetchCollectionData', async (payload) => {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Token ' + payload?.token,
            "X-Api-Key": APP_API_KEY,
        },
    };

    const response = await fetch(BASE_URL + 'data_types/data/' + payload?.activeCollectionID +'?app_id=' + payload?.app_id + '&start=' + payload?.start + '&limit=' + payload?.limit, options);
    return await response.json();
});

export const uploadFileToS3 = createAsyncThunk(
    's3Upload/uploadFileToS3',
    async (payload, { rejectWithValue }) => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: payload?.settings?.IdentityPoolId,
            IdentityId: payload?.settings?.IdentityId,
            Logins: {
                'cognito-identity.amazonaws.com': payload?.settings?.Token
            }
        });

        AWS.config.update( { region: payload?.settings?.region, credentials: AWS.config.credentials });
        const s3 = new AWS.S3({apiVersion: '2006-03-01'});
        const fileIdentifier = uuidv4() +  payload?.file.name.substr((payload?.file.name.lastIndexOf('.')));
        const params = {
            Bucket: payload?.settings?.bucket + "/private/" + payload?.settings?.IdentityId,
            Key: fileIdentifier,
            Body: payload?.file,
            ACL: "public-read",
            ContentType: payload?.file.type,
            IdentityPoolId: payload?.settings?.IdentityPoolId,
        };

        const options = {
            partSize: payload?.file.size * 1024 * 1024,
            queueSize: 1
        };

        try {
            return await s3.upload(params, options).promise();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const collectionSlice = createSlice({
    name: 'collection',
    initialState: {
        newCollection: undefined,
        activeCollectionID: undefined,
        activeCollectionFields: undefined,
        showCollectionSettings: false,
        showFileDropZone: false,
        showDataTypeOverlay: false,
        activeDataType: undefined,
        activeField: undefined,
        showDeleteCollectionField: false,
        showCollectionForm: false,
        activeCollection: undefined,
        showDeleteCollection: false,
        showAPIDocs: false,
        fields: [],
        files: [],
        uploadedFileData: undefined,
        error: undefined,
        isUploading: false,
        showFileUploadSuccess: false,
        start: 1,
        isLoadingCollectionData: false,
        collectionData: [],
    },
    reducers: {
        toggleDataTypeOverlay(state, action) {
            const { activeDataType, showDataTypeOverlay, activeField } = action.payload;
            state.activeDataType = activeDataType;
            state.showDataTypeOverlay = showDataTypeOverlay;
            state.activeField = activeField;
        },
        toggleNewCollection(state, action) {
            const { newCollection } = action.payload;
            state.newCollection = newCollection;
        },
        toggleActiveCollection(state, action) {
            const {activeCollectionID, showCollectionSettings} = action.payload;
            state.activeCollectionID = activeCollectionID;
            state.showCollectionSettings = showCollectionSettings;
        },
        toggleDeleteCollectionField(state, action) {
            const {showDeleteCollectionField, activeField} = action.payload;
            state.showDeleteCollectionField = showDeleteCollectionField;
            state.activeField = activeField;
        },
        toggleCollectionForm(state, action) {
            const { showCollectionForm, activeCollection } = action.payload;
            state.showCollectionForm = showCollectionForm;
            state.activeCollection = activeCollection;
        },
        toggleDeleteCollection(state, action) {
            const { showDeleteCollection, activeCollection } = action.payload;
            state.showDeleteCollection = showDeleteCollection;
            state.activeCollection = activeCollection;
        },
        toggleAPIDocs(state, action) {
            const { showAPIDocs, activeCollectionID } = action.payload;
            state.showAPIDocs = showAPIDocs;
            state.activeCollectionID = activeCollectionID;
        },
        toggleShowFileDropZone(state, action) {
            const { showFileDropZone } = action.payload;
            state.showFileDropZone = showFileDropZone;
        },
        updateFiles(state, action) {
            const { files } = action.payload;
            state.files = files;
        },
        toggleUploadedFileData(state, action) {
            const { uploadedFileData } = action.payload;
            state.uploadedFileData = uploadedFileData;
        },
        toggleFileUploadSuccess(state, action) {
            const { showFileUploadSuccess } = action.payload;
            state.showFileUploadSuccess = showFileUploadSuccess;
        },
        setStart(state, action) {
            const { start } = action.payload;
            state.start = start;
        },
        resetCollectionData(state) {
            state.collectionData = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFileToS3.pending, (state) => {
                state.showFileDropZone = false;
                state.isUploading = true;
                state.error = undefined;
            })
            .addCase(uploadFileToS3.fulfilled, (state, action) => {
                state.isUploading = false;
                state.uploadedFileData = Object.assign(
                    {},
                    {"name": state.files[0]["name"]},
                    {
                        "location": action.payload.Location,
                        "key": action?.payload?.Key,
                        "bucket": action?.payload?.Bucket
                    });
                state.files = [];
            })
            .addCase(uploadFileToS3.rejected, (state, action) => {
                state.isUploading = false;
                state.showFileDropZone = true;
                state.error = action.payload;
            }).addCase(fetchCollectionData.pending, (state) => {
                state.isLoadingCollectionData = true;
            })
            .addCase(fetchCollectionData.fulfilled, (state, action) => {
                state.collectionData = [...state.collectionData, ...action.payload];
                state.isLoadingCollectionData = false;
            });
    },
});

export const {
    toggleDataTypeOverlay,
    toggleNewCollection,
    toggleActiveCollection,
    toggleDeleteCollectionField,
    toggleCollectionForm,
    toggleDeleteCollection,
    toggleAPIDocs,
    updateFiles,
    toggleShowFileDropZone,
    toggleUploadedFileData,
    toggleFileUploadSuccess,
    setStart,
    resetCollectionData,
} = collectionSlice.actions;
export default collectionSlice.reducer;
