import * as React from "react";
import { Form, Field } from 'react-final-form'
import {useCreateAppMutation, useGetAppByIDQuery} from "../../../api/apiSlice";
import {useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {stripHtml} from "../../../constants";


const NewApp = () => {
    const {token} = useSelector(state => state.login)
    const [createApp, createAppResult] = useCreateAppMutation();
    const navigate = useNavigate();
    const {uuid} = useParams();
    const link = typeof uuid !== "undefined" ? "/!/apps/" + uuid: "/!";
    const {
        data: app,
        isLoading,
        refetch,
        isUninitialized
    } = useGetAppByIDQuery({
        "token": token,
        "uuid": uuid
    }, {skip: typeof uuid === "undefined"});

    const validate = values => {
        let errors = {};
        if (!values?.name || values?.name?.trim() === "") {
            errors.name = "You forgot to name your application."
        }
        if (!values?.description || values?.description?.trim() === "") {
            errors.description = "You forgot to provide a description of your application."
        }
        return errors;
    };

    const submit = values => {
        if ( values?.name !== '' && values?.description !== '') {
            values.name = stripHtml(values.name);
            values.description = stripHtml(values.description);
            createApp({
                "token": token,
                "values": values
            });
        }
    };

    React.useEffect(() => {
        if ( createAppResult?.status === "fulfilled" && createAppResult?.data?.hasOwnProperty("uuid")) {
            if (isUninitialized === false) refetch();
            navigate("/!/apps/" + createAppResult?.data?.uuid);
        }
    }, [createAppResult, isUninitialized, navigate, refetch])

    return(
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h4">{typeof uuid === "undefined" ? "New": "Edit"} App</h1>
            </div>
            {createAppResult?.isLoading || isLoading ? (<p>Working...</p>): null}
            <Form
                initialValues={app}
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Field
                            name="name"
                            render={({ input, meta }) => (
                                <div>
                                    <label className="form-label">Name</label>
                                    <input autoComplete="off" {...input} className="form-control" placeholder="App Name" disabled={!!(createAppResult?.isLoading || isLoading)} />
                                    {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                                </div>
                            )}
                        />

                        <Field
                            name="description"
                            render={({ input, meta }) => (
                                <div className="mt-4">
                                    <label className="form-label">Description</label>
                                    <textarea {...input} className="form-control" placeholder="App description" disabled={!!(createAppResult?.isLoading || isLoading)} />
                                    {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                                </div>
                            )}
                        />
                        { typeof uuid !== "undefined" &&
                            <Field
                                name="api_key"
                                render={({ input, meta }) => (
                                    <div className="mt-4">
                                        <label className="form-label">API KEY</label>
                                        <input {...input} className="form-control" disabled={true} />
                                        {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                                    </div>
                                )}
                            />
                        }
                        {
                            typeof uuid !== "undefined" &&
                            <Field
                                name="regenerate_api_key"
                                type="checkbox"
                                render={({ input, meta }) => (
                                    <div className="form-check mt-4">
                                        <input {...input} className="form-check-input" id="regenAPIKey" />
                                            <label className="form-check-label" htmlFor="regenAPIKey">
                                                Regenerate API KEY
                                            </label>
                                        <p className="text-muted mt-1">Check this box to regenerate your app's API key.</p>
                                    </div>
                                )}
                            />
                        }
                       <div className="mt-4">
                           <Link to={link} className="btn btn-outline-primary me-2">Cancel</Link>
                           <button type="submit" className="btn btn-primary" disabled={!!(createAppResult?.isLoading || isLoading)}>Submit</button>
                       </div>
                        {typeof createAppResult.error !== 'undefined' &&
                            createAppResult?.error?.data?.errors.map((error, index) => {
                                return (
                                    <div className="bg-warning p-4 mt-4" key={index}>
                                        {error}
                                    </div>
                                );
                            })}

                    </form>
                )}
            />
        </>
    )
}

export default NewApp;
