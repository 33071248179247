import * as React from "react";
import {useDispatch} from "react-redux";
import * as Icon from "react-bootstrap-icons";
import {removeSchemaField} from "../../../reducers/datasets";
import {Field} from "react-final-form";
import {Typeahead} from "react-bootstrap-typeahead";

const GeolocationField = ({isLoading, position}) => {
    const dispatch = useDispatch();
    return(
        <div className="card mt-3">
            <div className="card-header">
                Geolocation Field
            </div>
            <div className="card-body">
                <Field
                    name="name"
                    render={({ input, meta }) => (
                        <div>
                            <label className="form-label fw-bold">Schema Field Name</label>
                            <input
                                {...input}
                                autoComplete="off"
                                className="form-control"
                                placeholder="Enter field name"
                                disabled={isLoading}
                            />
                            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                        </div>
                    )}
                />
                <Field
                    name="required"
                    type="checkbox"
                    render={({ input, meta }) => (
                        <div className="form-check mt-4">
                            <input
                                {...input}
                                type="checkbox"
                                className="form-check-input"
                                id="required"
                                disabled={isLoading}
                            />
                            <label className="form-check-label fw-bold" htmlFor="required">Required</label>
                            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                        </div>
                    )}
                />


                <Field
                    name="options"
                    render={({ input, meta }) => (
                        <div className="mt-4">
                            <label className="form-label fw-bold" htmlFor="authentication_required">Format</label>
                            <Typeahead
                                id="options"
                                labelKey="name"
                                multiple={false}
                                allowNew={false}
                                onChange={(selected) => {
                                    // form.change("options", selected);
                                }}
                                options={['Raw Coordinates', 'Geo Hash']}
                                placeholder="Choose geolocation format"
                                disabled={isLoading}
                                // defaultSelected={typeof activeCollection?.authentication_required !== "undefined" && activeCollection?.authentication_required?.trim() !== "" ? activeCollection?.authentication_required?.trim()?.split(",") : []}
                            />
                            {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
                        </div>
                    )}
                />
            </div>
            <div className="card-footer">
                <button type="button" className="btn btn-phoenix-secondary" onClick={() => {
                    dispatch(removeSchemaField({
                        position: position
                    }));
                }}><Icon.Trash /> Remove</button>
            </div>
        </div>
    )
}

export default GeolocationField;
