import * as React from "react";
import Modal from "react-bootstrap/Modal";
import {toggleExitSchemaFormConfirmation} from "../../../reducers/datasets";
import {useDispatch, useSelector} from "react-redux";
import SchemaForm from "./SchemaForm";

const SchemaOverlay = () => {
    const {showSchemaForm} = useSelector(state => state.datasets);
    const dispatch = useDispatch();

    return(
        <>
            {
                showSchemaForm ? (
                    <Modal
                        show={showSchemaForm}
                        fullscreen={true}
                        onHide={() => {
                            dispatch(toggleExitSchemaFormConfirmation({
                                "showExitSchemaFormConfirmation": true,
                            }));
                        }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Dataset Schema</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SchemaForm />
                        </Modal.Body>
                    </Modal>
                ): null
            }
        </>
    )
}

export default SchemaOverlay;
