import {createSlice} from '@reduxjs/toolkit';

const prdSlice = createSlice({
    name: 'prd',
    initialState: {
      prd: '',
      status: '',
      showEditModal: false,
    },
    reducers: {
        setShowEditModal: (state, action) => {
            const {showEditModal} = action.payload;
            state.showEditModal = showEditModal;
        },
    },
    extraReducers: (builder) => {
      builder
        .addCase('prd/updatePRD', (state, action) => {
            const {status, chunk} = action.payload;
            state.prd += chunk;
            state.status = status;
        });
    },
  });

  export const { 
    updatePRD,
    setShowEditModal
  } = prdSlice.actions;
  export default prdSlice.reducer;