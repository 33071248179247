import {createSlice} from '@reduxjs/toolkit';

const datasetSlice = createSlice({
    name: 'datasets',
    initialState: {
        showSchemaForm: false,
        schemaFields: [],
        showExitSchemaFormConfirmation: false,
    },
    reducers: {
        toggleSchemaForm(state, action) {
            const { showSchemaForm, schemaFields } = action.payload;
            state.showSchemaForm = showSchemaForm;
            state.schemaFields = schemaFields;
        },
        addSchemaField(state, action) {
            const { field } = action.payload;
            state.schemaFields.push(field);
        },
        removeSchemaField(state, action) {
            const { position } = action.payload;
            state.schemaFields.splice(position, 1);
        },
        toggleExitSchemaFormConfirmation(state, action) {
            const { showExitSchemaFormConfirmation } = action.payload;
            state.showExitSchemaFormConfirmation = showExitSchemaFormConfirmation;
        },
    },
});

export const {
    toggleSchemaForm,
    addSchemaField,
    removeSchemaField,
    toggleExitSchemaFormConfirmation,
} = datasetSlice.actions;
export default datasetSlice.reducer;
