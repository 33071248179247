import * as React from 'react';
import {useLocation} from "react-router-dom";
import {
    useCreateSubscriptionMutation,
    useGetPaymentIntentQuery,
} from "../../api/apiSlice";
import {useSelector} from "react-redux";

const SubscriptionConfirmation = () => {
    const {token, user} = useSelector( state => state.login);
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const paymentIntentId = params.get('payment_intent');
    const redirectStatus = params.get('redirect_status');
    const [createSubscription, createSubscriptionResult] = useCreateSubscriptionMutation();

    const {
        data: paymentIntent,
        isLoading: isLoadingPaymentIntent,
    } = useGetPaymentIntentQuery({
        token: token,
        paymentIntentId: paymentIntentId
    });

    React.useEffect(() => {
        if (paymentIntent?.metadata?.user_id === user?.uuid && redirectStatus === "succeeded") {
            // update user subscription and redirect to home
            const start_date = Date.now();
            createSubscription({
                token: token,
                plan: {
                    price: paymentIntent?.metadata?.price,
                    app_limit: paymentIntent?.metadata?.app_limit,
                    request_limit: paymentIntent?.metadata?.request_limit,
                    completion_limit: paymentIntent?.metadata?.completion_limit,
                    uuid: paymentIntent?.metadata?.plan_id,
                    name: paymentIntent?.metadata?.name,
                },
                tool: paymentIntent?.metadata?.tool,
                start_date: start_date,
                paid_amount: paymentIntent?.amount/100,
            });
        }
    }, [createSubscription, paymentIntent, redirectStatus, token, user?.uuid])

    React.useEffect(() => {
        if (createSubscriptionResult?.status === 'fulfilled' && createSubscriptionResult?.data?.subscription?.uuid) {
            window.location.href = "/credits";
        }
    }, [createSubscriptionResult])

    return(
       <div className="content container">
           {isLoadingPaymentIntent && <p>Working...</p>}
       </div>
    )
}

export default SubscriptionConfirmation;
