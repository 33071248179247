import * as React from 'react';
import {useSelector} from "react-redux";

const ImportProgressMessage = () => {
    const {isUploading} = useSelector(state => state.collections);

    if ( isUploading ) return  (
        <div className="p-2">
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            Uploading file...
        </div>
    );
    return null;
};

export default ImportProgressMessage;
