import * as React from 'react';
import Dropzone from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";
import {updateFiles, uploadFileToS3} from "../../../reducers/collectionSlice";
import {useGetFileUploadAccessQuery} from "../../../api/apiSlice";

const FileDropzone = () => {
    const {token} = useSelector(state => state.login);
    const {files} = useSelector(state => state.collections);
    const dispatch = useDispatch();
    const {
        data: settings,
        isLoading,
    } = useGetFileUploadAccessQuery({
        "token": token,
    }, {skip: files.length === 0});

    const onDrop = (acceptedFiles) => {
        let parsedFiles = acceptedFiles.map(function (file) {
            file["extension"] = file.name.substr((file.name.lastIndexOf('.')));
            file["progress"] = 0;
            return file;
        });
        dispatch(updateFiles({"files": parsedFiles}));
    }

    React.useEffect(() => {
        if ( settings?.hasOwnProperty("IdentityId")) {
            dispatch(uploadFileToS3({
                "file": files[0],
                "settings": settings
            }));
        }
    }, [dispatch, files, settings]);

    return(
        <Dropzone
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
            accept={{"text/csv": [".csv"]}}
            maxFiles={1}
            multiple={false}
            maxSize={200000000}
            disabled={isLoading}
            inputContent={(files, extra) => (extra.reject ? 'Only CSV files are accepted' : 'Drag files to the boxed area to upload')}
        >
            {({getRootProps, getInputProps}) => (
                <section>
                    <div className="card mb-4 bg-light" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="card-body text-center" style={{
                            "height": "80px",
                        }}>
                           <p className="mt-2">
                               Drag 'n' drop CSV file here, or click to select from your computer
                           </p>
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    )
};

export default FileDropzone;
