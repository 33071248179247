import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {
    resetCollectionData,
    setStart,
    toggleActiveCollection,
    toggleDataTypeOverlay,
    toggleDeleteCollectionField,
    toggleFileUploadSuccess,
    toggleShowFileDropZone,
    toggleUploadedFileData
} from "../../../reducers/collectionSlice";
import * as Icon from "react-bootstrap-icons";
import {
    useGetCollectionByIDQuery,
    useGetCollectionDataQuery,
    useSaveFileDataMutation,
    useStartFileImportMutation
} from "../../../api/apiSlice";
import DataTypeOverlay from "./DataTypeOverlay";
import ConfirmDeleteCollectionField from "./Properties/ConfirmDeleteCollectionField";
import FileDropzone from "./FileDropzone";
import ImportProgressMessage from "./ImportProgressMessage";
import {Alert, AlertContainer} from "react-bs-notifier";


const CollectionSettings = ({app}) => {
    const {token} = useSelector(state => state.login);
    const {activeCollectionID, showCollectionSettings, showFileDropZone, uploadedFileData, showFileUploadSuccess, start} = useSelector(state => state.collections);
    const prevIntervalId = React.useRef(0);
    const observer = React.useRef();
    const [saveFile, saveFileResult] = useSaveFileDataMutation();
    const [startFileImport, startFileImportResult] = useStartFileImportMutation();
    const dispatch = useDispatch();
    const { data: collection, isLoading, refetch } = useGetCollectionByIDQuery({"token": token, "app": app, "activeCollectionID": activeCollectionID });
    const {
        data: collectionData,
        refetch: reFetchCollectionData,
        isFetching: isFetchingData
    } = useGetCollectionDataQuery({
        "token": token,
        "activeCollectionID": activeCollectionID,
        "app_id": app?.uuid,
        "start": start,
        "limit": 100
    }, {skip: false});

    React.useEffect(() => {
        reFetchCollectionData()
    }, [reFetchCollectionData, start]);

    React.useEffect(() => {
        if (typeof uploadedFileData !== "undefined" && saveFileResult?.status === 'uninitialized') {
            // Create node for each row in the file
            dispatch(toggleFileUploadSuccess({"showFileUploadSuccess": true}))
            saveFile({"token": token,"file": uploadedFileData});
            dispatch(toggleUploadedFileData({"uploadedFileData": undefined}));
        }
    }, [dispatch, saveFile, saveFileResult, token, uploadedFileData]);


    React.useEffect(() => {
       // if node created successfully, start the import table creation cron job
        if (saveFileResult?.status === 'fulfilled') {
            startFileImport({
                "token": token,
                "app_id": app?.uuid,
                "activeCollectionID": activeCollectionID,
                "file_id": saveFileResult?.data?.uuid
            });
            saveFileResult?.reset();
            // After fulfilled and reset we want to poll the collection data until it is populated
            prevIntervalId.current = setInterval(() => {
                if (collectionData?.length === 0) {
                    reFetchCollectionData()
                }
            }, 5000);
        }
    }, [activeCollectionID, app, collectionData, dispatch, reFetchCollectionData, saveFileResult, start, startFileImport, token]);

    // Re-fetch the collection data so that the new columns are displayed
    React.useEffect(() => { if (startFileImportResult?.status === 'fulfilled') { refetch(); } }, [refetch, startFileImportResult]);
    // We want the importing file data message to go away when the collection data is populated
    React.useEffect(() => {  if (collectionData?.length > 0) {  dispatch(toggleFileUploadSuccess({"showFileUploadSuccess": false})); } });

    const lastElementRef = React.useCallback(
        node => {
            if (isFetchingData) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && collectionData?.length > 100) { dispatch(setStart({"start": start + 100})); }
            });
            if (node) observer.current.observe(node);
        },
        [isFetchingData, collectionData?.length, dispatch, start]
    );

    React.useEffect(() => {
        if (collectionData?.length > 0) {
            clearInterval(prevIntervalId.current);
        }
    }, [collectionData?.length]);

    return(
        <>
            {
                showFileUploadSuccess &&
                <AlertContainer
                    position="top-left"
                >
                    <Alert
                        type="success"
                    >
                        <div className="p-2">
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            File uploaded! Importing data...please wait.
                        </div>
                    </Alert>
                </AlertContainer>
            }
            <Modal
                show={showCollectionSettings}
                fullscreen={true}
                onHide={() => {
                    dispatch(toggleActiveCollection({
                        activeCollection: undefined,
                        showCollectionSettings: false
                    }));
                    dispatch(resetCollectionData());
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1 className="h5">{collection?.name} Collection Properties</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        typeof collection !== "undefined"
                        &&
                        <div>
                            <div className="mb-2">
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle
                                        variant="dark"
                                        id="dropdown-basic"
                                    >
                                        <Icon.Plus/>Property
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'text',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            Text
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'number',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            Number
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'boolean',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            True/false
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'timestamp',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            Timestamp
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'password',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            Password
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'select',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            List of options
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                dispatch(toggleDataTypeOverlay({
                                                    showDataTypeOverlay: true,
                                                    activeDataType: 'gpt',
                                                    activeField: undefined
                                                }));
                                            }}
                                        >
                                            GPT Populated
                                        </Dropdown.Item>
                                    </Dropdown.Menu>

                                </Dropdown>
                                <Button
                                    variant="dark"
                                    className="ms-2"
                                    onClick={() => {
                                        dispatch(toggleShowFileDropZone({
                                            showFileDropZone: true
                                        }));
                                    }}
                                >
                                    <Icon.CloudUpload />{' '}Import
                                </Button>
                            </div>
                            {
                                showFileDropZone && (
                                    <div className="card border-0">
                                        <div className="card-header border-0 bg-dark">
                                            <button
                                                type="button"
                                                className="float-end btn-close"
                                                aria-label="Close"
                                                onClick={() => {
                                                    dispatch(toggleShowFileDropZone({
                                                        showFileDropZone: false
                                                    }));
                                                }}
                                            ></button>
                                        </div>
                                        <FileDropzone />
                                    </div>
                                )
                            }
                            {isLoading || isFetchingData ? (
                                <div className="p-2">
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    Working...
                                </div>
                            ): null}
                            <ImportProgressMessage />
                            <table className="table table-bordered table-dark table-hover">
                                <thead>
                                    <tr>

                                        {
                                            typeof collection?.fields !== "undefined" && collection?.fields?.length > 0 ? (
                                                collection.fields?.map((field, index) => {
                                                    return (
                                                        <th scope="col" key={index}>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="dark border-0 bg-transparent"
                                                                    id="settings"
                                                                >
                                                                    {field.name}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => {
                                                                        dispatch(toggleDataTypeOverlay({
                                                                            showDataTypeOverlay: true,
                                                                            activeDataType: field?.type,
                                                                            activeField: field
                                                                        }));
                                                                    }}><span className="me-2"><Icon.Pencil size="12" /></span> Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => {
                                                                        dispatch(toggleDeleteCollectionField({
                                                                            showDeleteCollectionField: true,
                                                                            activeField: field
                                                                        }));
                                                                    }}><span className="me-2"><Icon.Trash size="12" /></span>Delete</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </th>
                                                    );
                                                })
                                            ) : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    collectionData?.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                {
                                                    typeof collection?.fields !== "undefined" && collection?.fields?.length > 0 ? (
                                                        collection.fields?.map((field, index) => {
                                                            return (
                                                                <td key={index} dangerouslySetInnerHTML={{__html: row[field.name]}}></td>
                                                            );
                                                        })
                                                    ) : null
                                                }
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                            {
                                collectionData?.length === 0 && showFileDropZone === false ? (
                                    <div className="card mb-4">
                                        <div className="card-body text-center text-muted">
                                            <p>
                                                No data yet. Add some data to your collection to get started.
                                            </p>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {collectionData?.length > 0 && <span ref={lastElementRef}></span>}
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <DataTypeOverlay app={app} collection={collection} refetch={refetch} />
            <ConfirmDeleteCollectionField app={app} collection={collection} refetch={refetch} />
        </>
    )
};

export default CollectionSettings;
