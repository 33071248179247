import * as React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {useGetAppsQuery} from "../../../api/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {Alert, AlertContainer} from "react-bs-notifier";
import {toggleAppDeleted} from "../../../reducers/appSlice";
import NoApp from "./NoApp";

const Apps = () => {
    const {token} = useSelector(state => state.login);
    const {appDeleted} = useSelector( state => state.app);
    const alert = typeof appDeleted !== "undefined" && Object.keys(appDeleted).length > 0;
    const dispatch = useDispatch();
    const {
        data: apps,
        isLoading,
        isUninitialized,
        refetch
    } = useGetAppsQuery({"token": token});

    React.useEffect(() => {
        if (isUninitialized === false) refetch();
    }, [isUninitialized, refetch])

    return(
        <>
            {
                alert &&
                <AlertContainer position="top-left">
                    <Alert
                        type="success"
                        onDismiss={() => {
                            dispatch(toggleAppDeleted({}));
                        }}
                        timeout={200}
                    >
                        The app has been successfully deleted.
                    </Alert>
                </AlertContainer>
            }
            <div className="row gx-6 gy-3 mb-4 align-items-center">
                <div className="col-auto">
                    <h2 className="mb-0">Apps</h2>
                </div>
                <div className="col-auto">
                    <Link to="/!/copilot" className="btn btn-sm btn-primary me-2"><Icon.Robot /> Copilot</Link>
                    <Link to="/!/apps/new" className="btn btn-sm btn-primary"><Icon.Plus />New App</Link>
                </div>
            </div>
            <div>
                {
                    apps?.length > 0 ? (
                        apps?.map((app, index) => {
                            const link = '/!/apps/' + app?.uuid;
                            return(
                                <div className="card mt-4" key={index}>
                                    <div className="card-body">
                                        <Link to={link}><h5 className="card-title">{app?.name}</h5></Link>
                                        <p className="card-text">
                                            {app?.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    ): (
                        isLoading ? (<p>Loading...</p>) : <NoApp />
                    )
                }
            </div>
        </>
    )
}

export default Apps;
