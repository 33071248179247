import * as React from 'react';
import {Field, Form} from "react-final-form";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import {
    useCreatePRDMutation
} from "../../../api/apiSlice";
import {useState, useRef, useEffect} from "react";
import * as Icon from "react-bootstrap-icons";
import Card from "react-bootstrap/Card";
import { useNavigate } from 'react-router-dom';

const Copilot = () => {
    const {token} = useSelector(state => state.login);
    const {prd, status} = useSelector(state => state.prd);
    const [createPRD, createPRDResult] = useCreatePRDMutation();
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [firstSentence, setFirstSentence] = useState('');
    const prdRef = useRef(null);
    const navigate = useNavigate();

    const submit = async (values) => {
        // Get the JSON using copilot
        if (values?.description && values?.description?.trim() !== "") {
            try {
                const result = await createPRD(
                    {
                        "token": token,
                        "description": values?.description
                    }
                ).unwrap();
                if (result && result?.uuid) {
                    navigate(`/!/copilot/${result.uuid}`);
                }
              } catch (err) {
                console.error('Failed to create PRD:', err);
              }
        }
    }

    const validate = values => {
        let errors = {};
        if (!values?.description || values?.description?.trim() === "") {
            errors.description = "Hi there! I'm your agent. I need a description of your app to get started."
        } else if ( typeof values?.description !== "string" ) {
            errors.description = "Description must be a string.";
        } else if ( values?.description?.length > 1000 ) {
            errors.description = "Description must be less than 1000 characters.";
        }
        return errors;
    }

    const scrollToBottom = () => {
        if (prdRef.current) {
            prdRef.current.scrollTo({
                top: prdRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        if (status === 'generating' && prdRef.current) {
            prdRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        if (status === 'generating' || status === 'complete') {
            const sentences = prd.split(/(?<=[.!?])\s+/);
            if (sentences.length > 0) {
                setFirstSentence(sentences[0]);
            }
        }
    }, [prd, status]);

    useEffect(() => {
        const handleScroll = () => {
            if (prdRef.current && prd) {
                const { scrollTop, scrollHeight, clientHeight } = prdRef.current;
                const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                
                setIsAtBottom(scrollPercentage > 85);
            } else {
                setIsAtBottom(false);
            }
        };

        if (prdRef.current) {
            prdRef.current.addEventListener('scroll', handleScroll);
            handleScroll(); // Check initial scroll position
        }
        const currentPrdRef = prdRef.current;
        return () => {
            if (currentPrdRef) {
                currentPrdRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [prd]);

    const handlePresetClick = (preset) => {
        submit({ description: preset });
    };

    return(
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{ minHeight: '85vh' }}>
                    <div className="col-md-8">
                        { status !== "generating" && status !== "complete" ? (
                            <>
                                <h2 className="mb-1 mt-3 text-center">What would you like to build?</h2>
                                <p className="text-center mb-4 mx-auto" style={{ maxWidth: '550px' }}>I'll create a requirements document based on your idea, then build the app after your approval. Choose a preset option or describe your own concept.</p>
                                <div className="d-flex justify-content-center flex-wrap gap-3 mb-4">
                                    <button className="btn btn-outline-primary" onClick={() => handlePresetClick("Create a social networking app")}>
                                        <i className="bi bi-people-fill me-2"></i>
                                        Social networking app
                                    </button>
                                    <button className="btn btn-outline-primary" onClick={() => handlePresetClick("Build an e-commerce platform")}>
                                        <i className="bi bi-cart-fill me-2"></i>
                                        E-commerce platform
                                    </button>
                                    <button className="btn btn-outline-primary" onClick={() => handlePresetClick("Develop a task management tool")}>
                                        <i className="bi bi-journal-text me-2"></i>
                                        Task management tool
                                    </button>
                                    <button className="btn btn-outline-primary" onClick={() => handlePresetClick("Design an AI chatbot assistant")}>
                                        <i className="bi bi-chat-dots-fill me-2"></i>
                                        AI chatbot assistant
                                    </button>
                                </div>
                            </>
                        ) : null }
                        {
                            status === 'generating' || status === 'complete' ? (
                                <div className="mb-4 mt-4">
                                    <div className="position-sticky top-0 py-2" style={{zIndex: 1000}}>
                                        <p className="mb-2">{firstSentence}...</p>
                                        {status === 'complete' && (
                                            <Button variant="outline-light" size="sm">
                                                <Icon.PencilSquare className="me-2" size={16}/>
                                                Edit PRD
                                            </Button>
                                        )}
                                    </div>
                                    <div className="mb-4 mt-4" style={{height: '70vh', overflowY: 'auto'}} ref={prdRef}>
                                        <div dangerouslySetInnerHTML={{ __html: prd.replace(/\n/g, '<br />') }} />
                                    </div>
                                </div>
                            ) : (
                                <Form
                                    onSubmit={submit}
                                    validate={validate}
                                    render={( {handleSubmit} ) => (
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <Card className="mt-4 mb-4" style={{ border: 'none' }}>
                                            <Card.Body className="p-0">
                                                <Field
                                                    name="description"
                                                    render={({ input, meta }) => (
                                                        <div className="mt-4">
                                                            <textarea
                                                                {...input}
                                                                className="form-control border-0"
                                                                placeholder="Describe the app you would like to build in as much detail as possible"
                                                                disabled={createPRDResult?.isLoading}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                                        e.preventDefault();
                                                                        handleSubmit();
                                                                    }
                                                                }}
                                                            />
                                                            {!createPRDResult?.isLoading ? (
                                                                meta.touched && meta.error && <span className="text-danger p-2">{meta.error}</span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </Card.Body>
                                            <Card.Footer className="bg-transparent p-2" style={{ border: 'none' }}>
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        variant="outline-secondary me-2"
                                                        size="sm"
                                                        onClick={() => {
                                                            navigate("/!");
                                                        }}
                                                        disabled={createPRDResult?.isLoading}
                                                    >
                                                        Cancel
                                                    </Button>{' '}
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        disabled={createPRDResult?.isLoading}
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon.Send className="me-2" size={16}/>
                                                        Start app build process
                                                    </Button>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    </form>
                                    </>
                                    )}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            {!isAtBottom && (status === 'complete' || status === 'generating') ? (
                <div className="position-fixed bottom-0 start-50 translate-middle-x mb-4" style={{zIndex: 1200}}>
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={scrollToBottom}
                    className="rounded-circle"
                    style={{ width: '40px', height: '40px', padding: '0' }}
                >
                    <Icon.ArrowDown size={16} />
                </Button>
            </div>
            ): null}
        </>
    )
}

export default Copilot;
