import * as React from "react";
import * as Icon from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {useGetCollectionsQuery} from "../../../api/apiSlice";
import {
    toggleActiveCollection,
    toggleNewCollection,
    toggleCollectionForm,
    toggleDeleteCollection, toggleAPIDocs
} from "../../../reducers/collectionSlice";
import CollectionSettings from "./CollectionSettings";
import CollectionForm from "./CollectionForm";
import ConfirmDeleteCollection from "./ConfirmDeleteCollection";
import {API_URL} from "../../../constants";
import APIDocumentation from "./APIDocs";

const Collections = ({app}) => {
    const {token} = useSelector( state => state.login);
    const {newCollection, activeCollectionID} = useSelector(state => state.collections);

    const dispatch = useDispatch();
    const {
        data: collections,
        isLoading,
        refetch,
    } = useGetCollectionsQuery({
        "token": token,
        "app": app
    }, {skip: typeof app === "undefined"});

    React.useEffect(() => {
        if (typeof newCollection !== "undefined") refetch();
        dispatch(toggleNewCollection({newCollection: undefined}));
    }, [refetch, newCollection, dispatch])

    return(
        <>
            { isLoading && <p>Working...</p>}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-3 border-bottom">
                <h1 className="h6">Database Collections</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <Button type="button" className="btn btn-sm btn-primary" onClick={() => {
                            dispatch(toggleCollectionForm({
                                showCollectionForm: true,
                                activeCollection: undefined
                            }))
                        }}><Icon.Plus /> Collection</Button>
                    </div>
                </div>
            </div>
            {
                collections?.length > 0 ? (
                    collections?.map((collection, index) => {
                        const endpoint = API_URL + "/" + collection?.endpoint;
                        return(
                            <div className="card mt-4" key={index}>
                                <div className="card-body">
                                    <Button
                                        variant="link p-0 m-0"
                                        onClick={() => {
                                            dispatch(toggleActiveCollection({
                                                activeCollectionID: collection?.uuid,
                                                showCollectionSettings: true
                                            }));
                                        }}
                                    >
                                        <h5 className="card-title">
                                            {collection?.name}
                                        </h5>
                                    </Button>
                                    <p className="card-text">
                                        {collection?.description}
                                    </p>
                                    <p className="card-text text-muted">
                                        <Button
                                            variant="link"
                                            className="p-0"
                                            onClick={() => {
                                                dispatch(toggleAPIDocs({
                                                    showAPIDocs: true,
                                                    activeCollectionID: collection?.uuid
                                                }));
                                            }}
                                        >
                                            {endpoint}
                                        </Button>
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <div className="float-end">
                                        <Button
                                            variant="dark"
                                            onClick={() => {
                                                dispatch(toggleActiveCollection({
                                                    activeCollectionID: collection?.uuid,
                                                    showCollectionSettings: true
                                                }));
                                            }}
                                        >
                                            <Icon.Gear /> <span className="ms-1">Properties</span>
                                        </Button>
                                        <Button variant="dark" onClick={() => {
                                            dispatch(toggleCollectionForm({
                                                showCollectionForm: true,
                                                activeCollection: collection
                                            }))
                                        }}><Icon.Pencil /><span className="ms-1">Edit</span></Button>
                                        <Button variant="dark" onClick={() => {
                                            dispatch(toggleDeleteCollection({
                                                showDeleteCollection: true,
                                                activeCollection: collection
                                            }));
                                        }}><Icon.Trash /> <span className="ms-1">Delete</span></Button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ): (
                    isLoading ? (<p>Loading...</p>) : <div className="bg-light p-5 text-center mt-5">No collections have been created yet</div>
                )
            }
            { typeof activeCollectionID !== "undefined" && <CollectionSettings app={app} /> }
            { typeof activeCollectionID !== "undefined" && <APIDocumentation app={app} /> }
            <CollectionForm app={app} />
            <ConfirmDeleteCollection app={app} refetch={refetch} />
            <hr className="border-dark border-5" />
        </>
    )
};

export default Collections;
