import * as React from 'react';
import {MenuAppFill, Robot, FileText} from "react-bootstrap-icons";

const Sidebar = () => {
    return (
        <nav className="navbar navbar-vertical navbar-expand-lg">
            <div className="navbar-collapse collapse" id="navbarVerticalCollapse">
                <div className="navbar-vertical-content">
                    <ul className="navbar-nav flex-column" id="navbarVerticalNav">
                        <div>
                            <div className="nav-item-wrapper">
                                <li className="nav-item">
                                    <a className="nav-link label-1 active" href="/!/copilot" aria-current="page">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon">
                                                 <Robot
                                                     size={16}
                                                     title="Copilot"
                                                     color="white"
                                                 />
                                            </span>
                                            <span className="nav-link-text-wrapper">
                                                <span className="nav-link-text">Copilot</span>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </div>
                        <div>
                            <div className="nav-item-wrapper">
                                <li className="nav-item">
                                    <a className="nav-link label-1 active" href="/!" aria-current="page">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon">
                                                 <MenuAppFill
                                                     size={16}
                                                     title="Apps"
                                                     color="white"
                                                 />
                                            </span>
                                            <span className="nav-link-text-wrapper">
                                                <span className="nav-link-text">Apps</span>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </div>
                        <div>
                            <div className="nav-item-wrapper">
                                <li className="nav-item">
                                    <a className="nav-link label-1 active" href="/!/learn" aria-current="page">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-icon">
                                                 <FileText
                                                     size={16}
                                                     title="Learn"
                                                     color="white"
                                                 />
                                            </span>
                                            <span className="nav-link-text-wrapper">
                                                <span className="nav-link-text">Learn</span>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <div className="navbar-vertical-footer">
            </div>
        </nav>
    );
}

export default Sidebar;
