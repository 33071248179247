import * as React from 'react';

const Footer = () => {
    return(
        <footer className="footer position-absolute">
            <div className="row g-0 justify-content-between align-items-center h-100">
                <div className="col-12 col-sm-auto text-center">
                </div>
                <div className="col-12 col-sm-auto text-center">
                    Graxle, Inc. &copy; 2024
                </div>
            </div>
        </footer>
    )
}

export default Footer;
